import { useState } from 'react';
import { useMutation } from '@apollo/client';
import useCurrentUser from 'hooks/useCurrentUser';
import { SAVE_SYNC_UW_CALCULATOR_MAX_INVESTMENT_DATA } from './mutations';
import InvestmentCalculator from './components/InvestmentCalculator/InvestmentCalculator';
import StatusCodeErrorBlock from 'components/StatusCodeErrorBlock/StatusCodeErrorBlock';
import { NumberInput } from '@hometap/htco-components';
import { currency, currencyWithCents, percentage } from 'utils/numbers';
import AlertError from 'components/AlertError/AlertError';
import PaydownError from './components/PaydownError/PaydownError';
import { INPUT_FIELDS, INPUT_GROUPS } from './utils/calculatorConstants';
import useInvestmentCalculator from './hooks/useInvestmentCalculator';
import { getApplicationReviewPageUrls, getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import {
  riskBand6ErrorFromCriteria,
  rentalDebtServiceCoverageRatioErrorFromCriteria,
  rentalLienPositionErrorFromCriteria,
  rentalCompositeFicoErrorFromCriteria,
} from './utils/calculatorErrors';
import { GET_UW_CALCULATOR_MAX_INVESTMENT_DATA } from './mutations';
import './InvestmentCalculator.scss';
import useTrack from 'hooks/useTrackQuery';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import { CONFIGURATIONS_RESIDENCE_TYPE } from 'data/constants/configurations';
import { APP_REVIEW_ANCHORS } from '../track-details/ApplicationReview/data/constants/appReviewAnchors';

const NA = 'N/A';

const UWCalculatorController = () => {
  const { isInUnderwriterGroup, user } = useCurrentUser();
  const isUWCalcFieldsSummaryFlag = user?.rolloutFlags.includes('uw_calc_fields_in_summary_flag');
  const { track, refetchTrack } = useTrack();
  const [hasSaved, setHasSaved] = useState(false);

  const { inputData, updateInputDataByKey, calculatedData, calculationsIsLoading, calculationsError } =
    useInvestmentCalculator(GET_UW_CALCULATOR_MAX_INVESTMENT_DATA, track?.identifier, {
      highestSingleYearDelinquentTaxes: track.highestSingleYearDelinquentTaxes ?? 0,
      rb6Criteria: track.rb6Criteria ?? 0,
    });

  const [saveSyncMutation, { loading: saveSyncLoading, error }] = useMutation(
    SAVE_SYNC_UW_CALCULATOR_MAX_INVESTMENT_DATA,
    {
      onCompleted: result => setHasSaved(result.uwSaveSyncMaxInvestment?.ok),
      onError: () => setHasSaved(false),
    },
  );

  const saveSyncMaxInvestmentData = () => {
    saveSyncMutation({
      variables: {
        ...inputData,
        trackId: track?.identifier,
        investmentAmount: calculatedData?.investmentAmount,
      },
    });
  };

  if (!isInUnderwriterGroup) {
    return <StatusCodeErrorBlock errorCode={403} />;
  }

  const { liensUrl, applicationReviewUrl } = getApplicationReviewPageUrls(track?.identifier, user?.rolloutFlags);
  const { homeValuationsUrl } = getTrackDetailPageUrls(track?.identifier, user?.rolloutFlags);

  const rb6Error = riskBand6ErrorFromCriteria(calculatedData?.uwFailedEligibilityCriteria);
  const compositeFicoError = rentalCompositeFicoErrorFromCriteria(calculatedData?.uwFailedEligibilityCriteria);
  const lienPositionError = rentalLienPositionErrorFromCriteria(calculatedData?.uwFailedEligibilityCriteria);
  const debtServiceCoverageRatioError = rentalDebtServiceCoverageRatioErrorFromCriteria(
    calculatedData?.uwFailedEligibilityCriteria,
  );

  const renderInput = (key, type, args) => (
    <NumberInput
      type={type}
      placeholder="0"
      className="InvestmentCalculatorControllerInput"
      key={key}
      {...args}
      value={inputData[key]}
      onChange={newVal => {
        updateInputDataByKey(key, newVal || 0, track);
        setHasSaved(false);
      }}
    />
  );

  const valueOrNA = (value, func) => {
    return value ? func(value) : NA;
  };

  const { MORTGAGE_LIENS } = APP_REVIEW_ANCHORS;
  const payDownLink = `${applicationReviewUrl}${MORTGAGE_LIENS}`;

  let rb6Criteria;
  if (isUWCalcFieldsSummaryFlag) {
    rb6Criteria = track?.rb6Criteria ?? '-';
  } else {
    rb6Criteria = renderInput('rb6Criteria', 'integer', { max: 10 });
  }

  let highestSingleYearDelinquentTaxes;
  if (isUWCalcFieldsSummaryFlag) {
    highestSingleYearDelinquentTaxes = track?.highestSingleYearDelinquentTaxes
      ? currencyWithCents(track.highestSingleYearDelinquentTaxes)
      : '-';
  } else {
    highestSingleYearDelinquentTaxes = renderInput('highestSingleYearDelinquentTaxes', 'currency', { placeholder: 0 });
  }

  const baseGroups = [
    {
      group: INPUT_GROUPS.PROPERTY,
      rows: [
        {
          label: INPUT_FIELDS.HOME_VALUE,
          value: valueOrNA(track.beginningHomeValuation?.value, currency),
          link: homeValuationsUrl,
          additional: !track.beginningHomeValuation && <AlertError error="Please set a beginning home value" />,
          tooltipContent: (
            <>
              This value is being pulled from <b>Beginning Home Valuation.</b>
            </>
          ),
        },
        {
          label: INPUT_FIELDS.DEBT,
          value: currencyWithCents(track.totalDebtOnHome),
          link: liensUrl,
          tooltipContent: (
            <>
              This value is being pulled from <b>Total Lien Balance.</b>
            </>
          ),
        },
        {
          label: INPUT_FIELDS.STARTING_EQUITY,
          value: valueOrNA(track.startingEquityPercent, percentage),
        },
      ],
    },
    {
      group: INPUT_GROUPS.RISK_BAND,
      rows: [
        {
          label: INPUT_FIELDS.IWRB,
          value: track.incomeWeightedRiskBand.value || 'None',
        },
        {
          label: INPUT_FIELDS.RB6,
          value: rb6Criteria,
          additional: rb6Error && <AlertError error={rb6Error} />,
        },
      ],
    },
    {
      group: INPUT_GROUPS.PAYOFFS,
      rows: [
        {
          label: INPUT_FIELDS.FEE_PAYDOWN,
          value: valueOrNA(track.feePaydown, currencyWithCents),
          link: payDownLink,
          tooltipContent: (
            <>
              This value is being calculated from <b>Total Paydown minus Principal Paydown.</b>
            </>
          ),
        },
        {
          label: INPUT_FIELDS.PRINCIPAL_PAYDOWN,
          value: valueOrNA(track.principalPaydown, currencyWithCents),
          link: payDownLink,
          additional: <PaydownError calculatedData={calculatedData} includeCents={true} />,
          tooltipContent: (
            <>
              This value is being pulled from <b>Principal Paydown.</b>
            </>
          ),
        },
        {
          label: INPUT_FIELDS.TOTAL_PAYDOWN,
          value: valueOrNA(track.totalPaydown, currencyWithCents),
          link: null,
        },
      ],
    },
    {
      group: INPUT_GROUPS.WITHHOLDINGS,
      rows: [
        {
          label: INPUT_FIELDS.DELINQUENT_TAXES,
          value: highestSingleYearDelinquentTaxes,
        },
        {
          label: INPUT_FIELDS.WITHHOLDING_TOTAL,
          value: currencyWithCents(calculatedData?.withholdingTotal),
        },
      ],
    },
  ];

  let compositeFicoAlertError = null;
  if (track.compositeFicoData?.errorMessage) {
    compositeFicoAlertError = <AlertError error={track.compositeFicoData?.errorMessage} />;
  } else if (compositeFicoError) {
    compositeFicoAlertError = <AlertError error={compositeFicoError} />;
  }

  if (track.home?.residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Rental) {
    baseGroups.push({
      group: INPUT_GROUPS.RENTAL_ELIGIBILITY,
      rows: [
        {
          label: INPUT_FIELDS.COMPOSITE_FICO,
          value: track.compositeFicoData?.value ?? 'Missing',
          additional: compositeFicoAlertError,
        },
        {
          label: INPUT_FIELDS.LIEN_POSITION,
          value: valueOrNA(track.htLienPosition, val => val),
          additional: lienPositionError && <AlertError error={lienPositionError} />,
        },
        {
          label: INPUT_FIELDS.DEBT_SERVICE_COVERAGE_RATIO,
          value: track.debtServiceCoverageRatioInfo.dscrValue ?? 'Incomplete',
          additional: debtServiceCoverageRatioError && <AlertError error={debtServiceCoverageRatioError} />,
        },
      ],
    });
  }

  return (
    <div>
      <LoadingContainer isLoading={saveSyncLoading}>
        <InvestmentCalculator
          track={track}
          loading={calculationsIsLoading}
          headerError={error && 'Save & Sync of Maximum Investment Failed'}
          calculatedData={calculatedData}
          calculationsError={calculationsError}
          hasSaved={hasSaved}
          actionLabel="Save & Sync"
          onFetchTrack={refetchTrack}
          onAction={saveSyncMaxInvestmentData}
          inputGroups={baseGroups}
        />
      </LoadingContainer>
    </div>
  );
};

export default UWCalculatorController;
