import { currencyWithCents } from 'utils/numbers';
import { getRandomId, PARTIAL_CLAIM_LIEN_TYPE } from '../../../../utils/taskForms';
import { format } from 'date-fns';
import { SUMMARY_DIVIDER } from 'apps/track-details/ApplicationReview/components/Summary/Summary';
import { getNumberValue, validateUsd } from 'utils/validators';
import { getSelectionYesNoUnknownSelectedValue } from '../components/SelectionYesNoUnknown';
import { KIND_LITE_LIEN_PROPERTY_REPORT_FIELDS, LIEN_PROPERTY_REPORT_FIELDS } from '../components/data/constants';

export const getNewLienSection = () => {
  const newLienIdentifier = `new-${getRandomId()}`;
  const formattedDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  return {
    newLien: true,
    identifier: newLienIdentifier,
    holder: '',
    lender: '',
    notes: '',
    maxLineAmount: '',
    currentBalance: '',
    principalAmount: '',
    interestAmount: '',
    escrowAmount: '',
    pastDueAmount: '',
    pastDueFees: '',
    asOfDate: '',
    isForbearance: '',
    kind: '',
    mortgageInvestorKind: '',
    position: '',
    originalAmount: '',
    rate: '',
    rateType: '',
    mortgageAccountNumber: '',
    isMoreThanOneMonthPastDue: '',
    lienDate: '',
    endOfTermDate: '',
    subSectionLabel: 'New lien',
    selectorId: newLienIdentifier,
    createdAt: formattedDate,
  };
};

export const stringToFloat = str => parseFloat(str) || 0.0;

export const getIsLienPrincipalAmountBalance = (lienKind = '', lienKindsPrincipalAmountBalance = []) =>
  lienKindsPrincipalAmountBalance.includes(lienKind.toLowerCase());

export const hasLienPayoff = paydown => paydown && (paydown.isPayoff === 'true' || paydown.isPayoff === true);

/**
 * Liens that in the lienKindsPrincipalAmountBalance need to be handled differently from other
 * types of liens.
 *    Use the principal paydown amount if Partial Claim and has payoff or original amount
 *    Use the principal paydown amount if lien has paydown or undefined
 *
 * Used to calculate Balance and:
 *    Display Balance values in the tabs
 *    Compare Balance with paydown amount
 *
 * Returns:
 *    original amount or principal paydown amount
 */
export const getLienPrincipalAmountBalance = (lien, hasPayoff) => {
  const isPartialClaim = lien?.kind === PARTIAL_CLAIM_LIEN_TYPE;

  if (isPartialClaim) {
    return hasPayoff ? lien.paydown.principalPaydownAmount : lien.originalAmount;
  }

  return lien?.paydown?.principalPaydownAmount;
};

/**
 * Liens that are HELOCs need to be handled differently from other types of liens.
 *    Use the max line amount if it is greater than balance
 *
 * Liens that are in lienKindsPrincipalAmountBalance need to be handled differently from other types of liens.
 * Their balance are calculated in the getLienPrincipalAmountBalance function.
 *
 * Returns:
 *    current balance or max line amount or original amount or principal paydown amount
 */
export const getLienCurrentBalance = (lien, lienKindsPrincipalAmountBalance = []) => {
  const isLienPrincipalAmountBalance = getIsLienPrincipalAmountBalance(lien?.kind, lienKindsPrincipalAmountBalance);
  const hasPayoff = hasLienPayoff(lien?.paydown);

  if (isLienPrincipalAmountBalance) {
    return getLienPrincipalAmountBalance(lien, hasPayoff);
  }

  return lien?.currentBalance || lien?.maxLineAmount;
};

/**
 * The Total Lien Balance is determined as the sum of the maximum values between
 * currentBalance and maxLineAmount for each lien, considering only those liens
 * that are documented on the property report. If the lien has a payoff, always
 * use the currentBalance.
 */
const relevantBalance = (currentBalance = 0, maxLineAmount = 0, lien = null, lienKindsPrincipalAmountBalance) => {
  const hasPayoff = hasLienPayoff(lien?.paydown);
  const isLienPrincipalAmountBalance = getIsLienPrincipalAmountBalance(lien?.kind, lienKindsPrincipalAmountBalance);

  if (isLienPrincipalAmountBalance) {
    return { label: 'Balance', amount: getLienPrincipalAmountBalance(lien, hasPayoff) || 0 };
  }

  if (!currentBalance && !maxLineAmount) return { label: 'Balance', amount: 0 };
  return currentBalance && (!maxLineAmount || hasPayoff || stringToFloat(currentBalance) > stringToFloat(maxLineAmount))
    ? { label: 'Balance', amount: currentBalance }
    : { label: 'Max Line Amount', amount: maxLineAmount };
};

/**
 * The Total Paydown Balance is determined as the sum of the feePaydownAmount and principalPaydownAmount
 */
const relevantPaydownBalance = paydown => {
  if (!paydown) return {};
  const { feePaydownAmount = 0, principalPaydownAmount = 0 } = paydown;
  return { label: 'PD: ', amount: feePaydownAmount + principalPaydownAmount };
};

export const getLienSectionsData = (liens, lienKindsPrincipalAmountBalance) => {
  const combinedLiensData = liens?.sort(getSortedSections)?.reduce(
    (acc, lien) => {
      const { onPropertyReport, currentBalance, maxLineAmount, identifier, isFormValid } = lien;
      const { principalPaydownAmount, feePaydownAmount } = lien?.paydown || {};

      const isOnPropertyReport = getSelectionYesNoUnknownSelectedValue(onPropertyReport);
      const { label, amount } = relevantBalance(currentBalance, maxLineAmount, lien, lienKindsPrincipalAmountBalance);
      const { label: additionalLabel, amount: additionalAmount } = relevantPaydownBalance(lien?.paydown);
      if (amount && isOnPropertyReport) {
        acc.totalBalance += Number(amount);
      }
      if (principalPaydownAmount) {
        acc.principalPaydown += Number(principalPaydownAmount);
      }
      if (feePaydownAmount) {
        acc.feePaydown += Number(feePaydownAmount);
      }
      acc.sections = [
        ...acc.sections,
        {
          ...lien,
          identifier,
          valueLabel: `${label}: `,
          balanceAmount: stringToFloat(amount),
          value: currencyWithCents(amount),
          additionalLabel,
          additionalValue: currencyWithCents(additionalAmount),
          isFormValid,
        },
      ];
      return acc;
    },
    {
      sections: [],
      totalBalance: 0,
      principalPaydown: 0,
      feePaydown: 0,
    },
  );
  const htLienPosition = calculateHtLienPosition(liens);
  return { ...combinedLiensData, htLienPosition };
};

export const getSortedSections = (a, b) => {
  if (a.position && b.position) {
    return a.position - b.position;
  }
  if (a.position || b.position) {
    return a.position ? -1 : 1;
  }
  return new Date(a.createdAt) - new Date(b.createdAt);
};

const lienToSection = (lien, liensKindOptions) => {
  const type = liensKindOptions.find(valLabelPair => valLabelPair.value === lien.kind.toLowerCase());
  return {
    ...lien,
    ...{
      subSectionLabel: lien.position ? `(${lien.position}) ${type?.label}` : type?.label,
      selectorId: lien.identifier,
    },
  };
};

export const getInitialSectionsLiens = (liens, liensKindOptions) =>
  liens?.map(lien => lienToSection(lien, liensKindOptions))?.sort(getSortedSections);

// Does this ID represent a draft/new app review item? (e.g. new-12345)
export const getIsNewId = identifier => (identifier ? identifier.startsWith('new') : false);

export const getLabel = ({ label, required = true }) => `${label}${required ? '' : ' (optional)'}`;

export const updateArrayByKey = (array, key, newValues) => {
  return array.map(item => (item.identifier === key ? { ...item, ...newValues } : item));
};

export const getSummaryLiensParams = ({ principalPaydown, feePaydown, htLienPosition, totalBalance, totalPaydown }) => [
  {
    label: 'HT Anticipated lien position',
    labelClassName: 'SummaryLienLabel',
    value: htLienPosition,
    className: 'SummaryLien',
    tooltip:
      'The Anticipated Lien Position takes into account all liens that are (or are in the process of being) listed on this track.',
  },
  {
    label: 'Total lien balance',
    labelClassName: 'SummaryLienLabel',
    value: totalBalance,
    tooltip: 'The sum of principal balances for liens that appear on the property report.',
  },
  SUMMARY_DIVIDER,
  {
    label: 'Principal paydown',
    labelClassName: 'SummaryLienLabelNormal',
    value: currencyWithCents(principalPaydown),
    isIndented: true,
    isWithExtraTop: true,
  },
  {
    label: 'Fee paydown',
    labelClassName: 'SummaryLienLabelNormal',
    value: currencyWithCents(feePaydown),
    isIndented: true,
    isWithExtraBottom: true,
  },
  SUMMARY_DIVIDER,
  { label: 'Total paydown', labelClassName: 'SummaryLienLabel', value: currencyWithCents(totalPaydown) },
];

/**
 * The lien position is calculated as the total number of liens minus the number of paid-off liens (where lien.paydown.isPayoff is true), plus 1. This is because Hometap will be one step behind all of the liens.
 * @param {Array} liens - An array of lien objects. Each lien object should have a 'paydown' property which is an object with an 'isPayoff' property.
 * @returns {number} The calculated Hometap's lien position.
 */
export const calculateHtLienPosition = liens => {
  const numberOfLiens = liens?.length || 0;
  const nonSeniorLiens =
    liens?.filter(
      lien =>
        lien.paydown?.isPayoff === 'true' ||
        lien.paydown?.isPayoff === true ||
        lien.onPropertyReport === 'false' ||
        lien.onPropertyReport === false ||
        lien.kind === 'UCC',
    ).length || 0;
  return numberOfLiens - nonSeniorLiens + 1;
};

export const getIsAmountValuePresent = value => value !== null && value !== '' && value !== undefined;

export const getTotalAmount = values => {
  const validValues = values.filter(getIsAmountValuePresent);
  return validValues.length ? validValues.reduce((total, value) => total + getNumberValue(value), 0) : undefined;
};

export const validateBalance = ({ value, valueToCompare, fieldName, errorText }) => {
  if (!getIsAmountValuePresent(value) || !getIsAmountValuePresent(valueToCompare)) {
    return undefined;
  }
  const error = validateUsd(value);
  if (error) {
    return error;
  }
  if (getNumberValue(value) > getNumberValue(valueToCompare)) {
    return fieldName ? `${fieldName} is greater than the current principal balance.` : errorText;
  }
};

export const getLienPropertyReportFields = kind => {
  return (
    KIND_LITE_LIEN_PROPERTY_REPORT_FIELDS.find(({ kinds }) => kinds.includes(kind?.toLowerCase()))?.fields ||
    LIEN_PROPERTY_REPORT_FIELDS
  );
};

export const getLienPropertyReportFieldsSettings = kind =>
  KIND_LITE_LIEN_PROPERTY_REPORT_FIELDS.find(({ kinds }) => kinds.includes(kind?.toLowerCase())) || {};

export const getSuccessNotificationDescription = (newLien, lienType) =>
  newLien ? `${lienType} successfully created.` : `Changes to ${lienType} successfully saved.`;

export const getErrorNotificationDescription = (newLien, lienType) =>
  `Could not ${newLien ? `create ${lienType}` : `save changes to ${lienType}`}. Please try again.`;

export const getParsedValue = (currentValue, normalizeCase = true) => {
  let value;

  try {
    value = JSON.parse(currentValue);
  } catch (e) {
    value = currentValue;
  }

  return typeof value === 'string' && normalizeCase ? value.toLowerCase() : value;
};
