import { Link } from 'react-router-dom';
import { getTrackDetailPageUrls, getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import useCurrentUser from 'hooks/useCurrentUser';
import { UseCurrentUser } from 'hooks/types/types';
import { TrackLinkProps } from './types/types';

const TrackLink = ({ friendlyId, trackId, disabled }: TrackLinkProps) => {
  const { isInApplicationSpecialistGroup, isInUnderwriterGroup } = useCurrentUser() as UseCurrentUser;
  const { stageUrl } = getTrackDetailPageUrls(trackId);
  const { applicationReviewUrl } = getApplicationReviewPageUrls(trackId);

  if (disabled) {
    return (
      <span data-test={friendlyId} className="DisabledLink">
        {friendlyId}
      </span>
    );
  }
  if (isInApplicationSpecialistGroup) {
    return (
      <Link data-test={friendlyId} to={`${trackId}/tasks`}>
        {friendlyId}
      </Link>
    );
  }

  if (isInUnderwriterGroup) {
    return (
      <a data-test={friendlyId} href={applicationReviewUrl}>
        {friendlyId}
      </a>
    );
  }

  return (
    <a data-test={friendlyId} href={stageUrl}>
      {friendlyId}
    </a>
  );
};

export default TrackLink;
