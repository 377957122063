import React, { useEffect, useState, useCallback } from 'react';
import { Button, DataTable, FormError, Icon, Loader, ProgressBar, useEscape } from '@hometap/htco-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { bytesToSize, formatErrorMessage, prepareFileForUpload } from 'utils/documents';
import { SlideInFromRight } from '../SlideInFromRight/SlideInFromRight';
import { capitalize, cloneDeep, isEmpty } from 'lodash';
import {
  handleRelatedDocumentTasks,
  toggleDocumentPin,
  uploadDocument,
  uploadMultipleFilesIntoPdf,
  uploadNewVersion,
} from '../../documentRequests';
import { StaticDocumentType } from '../StaticDocumentType/StaticDocumentType';
import './UploadDocumentProcessing.scss';
import {
  showMergeDocumentsToastByType,
  showUploadNewVersionToast,
  showUploadDocumentToast,
  showUploadDocumentsToast,
} from '../../DocumentToasts';
import { getMergeErrorTypeAndText } from '../../DocumentsUtils';
import { DOCUMENT_VISIBILITY } from '../../data/constants';
import { OVERRIDE_DOCUMENT_KIND_VISIBILITY } from '../VisibilityRadioRow/VisibilityRadioRow';
import { MERGE_DOCUMENTS_TOAST } from '../../data/constants';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import { GET_TODOS } from 'apps/todos/queries';
import { showNotification } from 'utils/toasts';

/**
 * second pane when uploading a document. Displays uploading progress for each file.
 * @param {bool} open
 * @param {callback} close
 * @param {array} files - Uploaded files
 * @param {string} documentType
 * @param {string} otherType
 * @param {string} trackId
 * @param {callback} refresh
 * @param {object} useDocumentKindsResults
 * @param {object} versionToBeUpdated - Object of file that will be updated with a new version
 * @param {object} newDocumentFields - Object of the new file that will be created with a new version or multiple uploading
 * @param {boolean} isCombine - Whether combine into single PDF file
 * @returns
 */
export const UploadDocumentProcessing = ({
  open,
  close,
  files,
  documentType,
  useDocumentKindsResults,
  otherType,
  trackId,
  refresh,
  versionToBeUpdated,
  newDocumentFields,
  isCombine,
  closable = true,
  defaultVisibilityLabel,
  isDefaultVisibilityEditable,
  applicant,
}) => {
  const [fileIdToLoadingObject, setFileIdToLoadingObject] = useState({});
  const [error, setError] = useState();
  const [numberUploaded, setNumberUploaded] = useState(0);
  const [shouldRefreshOnSliderClose, setShouldRefreshOnSliderClose] = useState(false);
  const client = useApolloClient();
  const { postMessage } = useBroadcastChannel({
    channelName: 'DOCS_UPDATED',
  });

  const { refetch: refetchUnifiedTasks } = useQuery(GET_TODOS, {
    variables: { trackIds: [trackId] },
  });

  // complete any homeowner todos associated with this document and spawn review tasks
  const handleResolveTasks = async uploaded_doc_id => {
    const { todo_completed, todo_name } = await handleRelatedDocumentTasks(uploaded_doc_id);
    if (todo_completed) {
      showNotification({
        type: 'success',
        title: 'To-do marked complete',
        description: `"${todo_name}" has been marked complete.`,
      });
    }
    await refetchUnifiedTasks();
  };

  // this file name will be defined only if we are merging the documents from upload or uploading a new version of the document
  let filenameToCombine;
  if (newDocumentFields.name) {
    filenameToCombine = `${newDocumentFields.name}.pdf`;
  } else if (files.length > 1 && isCombine) {
    filenameToCombine = files[0].file.name;
  }

  let fileMaxWidth = '360px';
  if (isCombine) {
    fileMaxWidth = '565px';
  }
  if (isCombine && files.length > 1) {
    fileMaxWidth = '615px';
  }

  const columns = [
    {
      name: <strong>File</strong>,
      minWidth: '100px',
      maxWidth: fileMaxWidth,
      selector: ({ file }) => file.name,
      cell: ({ file }) => (
        <span className="UploadDocumentProcessingFileNameContainer">
          <strong>{file.name}</strong>
          <div className="UploadDocumentProcessingFileNameSize">
            {file.size && <span>{bytesToSize(file.size, 1)}</span>}
          </div>
        </span>
      ),
    },
    {
      name: <strong>Visibility</strong>,
      selector: row => null,
      width: '205px',
      omit: isCombine,
      cell: ({ file }) => (
        <span>{isDefaultVisibilityEditable ? capitalize(file.visibility) : defaultVisibilityLabel}</span>
      ),
    },
    {
      name: <strong>Pin</strong>,
      selector: row => null,
      width: '50px',
      omit: isCombine && files.length > 1,
      cell: ({ file }) => (
        <Icon
          className="UploadDocumentProcessingPinIcon"
          name={file.pinned || !file.hasOwnProperty('pinned') ? 'icon-pin-fill' : 'icon-pin'}
        />
      ),
    },
    {
      name: null,
      right: true,
      maxWidth: '75px',
      cell: file => {
        const isLoading = fileIdToLoadingObject[file.id] ? fileIdToLoadingObject[file.id].loading : true;
        return (
          <span>
            {isLoading ? (
              <Loader />
            ) : (
              <Icon name={`icon-${fileIdToLoadingObject[file.id].error ? 'error' : 'check-mdc'}`} size="2x" />
            )}
          </span>
        );
      },
    },
  ];

  const customStyles = {
    table: {
      style: {
        marginBottom: '8px',
      },
    },
  };

  // Set all documents to uploaded unless there is an errormessage
  const setAllDocumentUploaded = (errorMessage = null) => {
    const uploadedDocuments = {};
    const populateUploadedDocuments = files.map(
      file => (uploadedDocuments[file.id] = { loading: false, error: Boolean(errorMessage) }),
    );
    Promise.all(populateUploadedDocuments).then(() => {
      setFileIdToLoadingObject(uploadedDocuments);
      setNumberUploaded(errorMessage ? 0 : files.length);
      setError(formatErrorMessage(errorMessage));
    });
  };

  // Prepare the file and handle errors if there is an issue preparing
  const handlePrepareFile = async (file, loadingObject) => {
    try {
      return await prepareFileForUpload(file);
    } catch ({ message }) {
      loadingObject[file.id] = { loading: false, error: true };
      setFileIdToLoadingObject(loadingObject);
      setError(formatErrorMessage(message));
    }
  };

  // upload the document and handle errors if there are issues
  const handleUploadDocument = async (uploadDoc, loadingObject) => {
    try {
      const { id } = await uploadDocument({
        trackId,
        file: uploadDoc.file,
        kind: documentType,
        kind_other: otherType,
        personId: applicant?.person?.identifier,
      });
      if (uploadDoc.file.pinned) {
        await toggleDocumentPin(id, uploadDoc.file.pinned);
      } else if (!uploadDoc.file.hasOwnProperty('pinned')) {
        await toggleDocumentPin(id, true);
      }
      loadingObject[uploadDoc.id] = { loading: false, error: false };
      setFileIdToLoadingObject(loadingObject);
      setNumberUploaded(
        Object.values(loadingObject).filter(({ loading, error }) => loading === false && error === false).length,
      );
      return { id };
    } catch (error) {
      const { message, response } = error;
      if (
        response?.status === 400 &&
        Array.isArray(response?.data?.file) &&
        typeof response?.data?.file[0] === 'string'
      ) {
        setError(response?.data?.file[0]);
      } else {
        setError(formatErrorMessage(message));
      }
      loadingObject[uploadDoc.id] = { loading: false, error: true };
      setFileIdToLoadingObject(loadingObject);
      return { error };
    }
  };

  const handleCompleteUploadingFile = async uploadDocId => {
    if (uploadDocId) {
      try {
        const handleRefetchQueries = async () => {
          const newMessage = {
            trackId,
            updatedAt: new Date(),
          };
          postMessage(newMessage);
          await client.refetchQueries({ include: ['GetInternalTaskDataForTrack'] });
        };
        await handleResolveTasks(uploadDocId);
        await handleRefetchQueries();
      } catch ({ message }) {
        setError(formatErrorMessage(message));
      }
    }
  };

  const handleOneFileUpload = async (file, tempLoadingObject) => {
    const uploadDoc = await handlePrepareFile(file, tempLoadingObject);
    const { id } = await handleUploadDocument(uploadDoc, tempLoadingObject);
    if (id) {
      showUploadDocumentToast(uploadDoc.file.name);
      await handleCompleteUploadingFile(id);
    }
  };

  // loop through files and upload one-by-one
  const handleMultipleFilesUpload = async (files, tempLoadingObject) => {
    let uploadedDocumentId;
    let uploadedDocumentError;
    /**
     * Process each file in the 'files' array sequentially.
     * We are using 'reduce' to avoid an error Unexpected `await` inside a loop.
     * If it's the first successful upload, store its ID in 'uploadedDocumentId'.
     * If an error occurs during the upload, store the error in 'uploadedDocumentError'.
     */
    await files.reduce(async (previousPromise, file) => {
      await previousPromise;
      const uploadDoc = await handlePrepareFile(file, tempLoadingObject);
      const { id, error } = await handleUploadDocument(uploadDoc, tempLoadingObject);
      if (!uploadedDocumentId && id) {
        uploadedDocumentId = id;
      }
      if (error) {
        uploadedDocumentError = error;
      }
    }, Promise.resolve());

    if (!uploadedDocumentError) {
      showUploadDocumentsToast(files.length);
    }

    if (uploadedDocumentId) {
      await handleCompleteUploadingFile(uploadedDocumentId);
      if (uploadedDocumentError) {
        setShouldRefreshOnSliderClose(true);
      }
    }
  };

  const getInternalValue = () => {
    const overriddenVisibility = OVERRIDE_DOCUMENT_KIND_VISIBILITY[documentType];
    return overriddenVisibility ? overriddenVisibility === DOCUMENT_VISIBILITY.internal : newDocumentFields.internal;
  };

  useEffect(() => {
    const handleMergedDocumentUpload = async () => {
      const { id } = await uploadMultipleFilesIntoPdf({
        files,
        trackId,
        fileName: newDocumentFields.name,
        internal: newDocumentFields.internal,
        kind: documentType,
        kind_other: otherType,
        personId: applicant?.person?.identifier,
      });
      await handleResolveTasks(id);
      const pinned = !newDocumentFields.hasOwnProperty('pinned') || newDocumentFields.pinned;
      if (pinned) {
        await toggleDocumentPin(id, pinned);
      }
    };

    if (open) {
      const tempLoadingObject = cloneDeep(fileIdToLoadingObject);
      // populate fileIdToLoadingObject if empty
      if (Object.keys(tempLoadingObject).length === 0) {
        for (const file of files) {
          tempLoadingObject[file.id] = { loading: true, error: false };
        }
        setFileIdToLoadingObject(tempLoadingObject);
      }
      if (!isEmpty(versionToBeUpdated)) {
        const pinned =
          files.length > 1
            ? !newDocumentFields.hasOwnProperty('pinned') || newDocumentFields.pinned
            : !files[0]?.file.hasOwnProperty('pinned') || files[0]?.file.pinned;
        uploadNewVersion({
          files,
          newVersionDocId: versionToBeUpdated.code,
          fileName: newDocumentFields.name,
          internal: getInternalValue(),
          pinned,
          kind: versionToBeUpdated.kind_old_code,
          kindOther: otherType,
          personId: applicant?.person?.identifier,
        })
          .then(() => {
            setAllDocumentUploaded();
          })
          .catch(error => {
            const maybeData = error?.response?.data;
            const errorMessage = maybeData?.file?.[0] || maybeData?.join(' ') || error?.message;
            setAllDocumentUploaded(errorMessage);
          });
      } else if (isCombine) {
        handleMergedDocumentUpload()
          .then(() => {
            setAllDocumentUploaded();
          })
          .catch(error => {
            if (
              error?.response?.status === 400 &&
              Array.isArray(error?.response?.data) &&
              typeof error?.response?.data[0] === 'string'
            ) {
              const errorText = error?.response?.data.join(' ');
              setAllDocumentUploaded(errorText);
            } else {
              setAllDocumentUploaded(error?.message);
            }
          });
      } else {
        files.length === 1
          ? handleOneFileUpload(files[0], tempLoadingObject)
          : handleMultipleFilesUpload(files, tempLoadingObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, files, trackId]);

  // show the toast with an error if the document merge fails
  useEffect(() => {
    if (error && filenameToCombine) {
      const { errorText, type } = getMergeErrorTypeAndText(error);
      showMergeDocumentsToastByType(filenameToCombine, type, 0, errorText);
      setError();
    } else if (error) {
      // we should hide the error if we don't show the toast and use the FormError
      setTimeout(setError, 7000, undefined);
    }
  }, [error, filenameToCombine]);

  const resetAndRefresh = useCallback(() => {
    setFileIdToLoadingObject({});
    setError();
    setNumberUploaded(0);
    refresh();
  }, [setFileIdToLoadingObject, setError, setNumberUploaded, refresh]);

  const handleClose = useCallback(() => {
    resetAndRefresh();
    close();
  }, [close, resetAndRefresh]);

  // close slider when all files are uploaded without error
  useEffect(() => {
    if (!error && open && numberUploaded === files.length) {
      if (filenameToCombine) {
        if (versionToBeUpdated?.id) {
          showUploadNewVersionToast(versionToBeUpdated.filename);
        } else {
          showMergeDocumentsToastByType(filenameToCombine, MERGE_DOCUMENTS_TOAST.Success, files.length);
        }
      } else if (versionToBeUpdated?.id) {
        showUploadNewVersionToast(versionToBeUpdated.filename);
      }
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [error, files, handleClose, numberUploaded, open, filenameToCombine, versionToBeUpdated]);

  /** Refresh documents immediately after the slider is closed, without any delay.
   * This is particularly used for refreshing documents after a bulk upload operation
   * where one or more files have encountered an error.
   */
  useEffect(() => {
    return () => {
      if (shouldRefreshOnSliderClose) {
        resetAndRefresh();
        setShouldRefreshOnSliderClose(false);
      }
    };
  }, [shouldRefreshOnSliderClose, resetAndRefresh]);

  useEscape(true, close);

  return (
    <SlideInFromRight open={open} close={close} closable={closable}>
      <div className="UploadDocumentProcessing">
        <h2>Upload Document</h2>
        <StaticDocumentType
          otherType={otherType}
          useDocumentKindsResults={useDocumentKindsResults}
          documentType={documentType}
          applicant={applicant}
        />

        <div className="UploadDocumentProcessingDocumentsFileList">
          <h4 className="UploadDocumentProcessingDocumentsFileListHeading">Documents</h4>
          <hr className="UploadDocumentsProcessingDocumentsFileListDivider" />
          <DataTable
            columns={columns}
            data={files ?? []}
            noDataComponent="There are no files to upload."
            customStyles={customStyles}
          />
        </div>
        <div className="UploadDocumentProcessingProgressBarContainer">
          Uploading...
          <ProgressBar steps={files.length} curStep={numberUploaded} />
        </div>
        {!filenameToCombine && <FormError standalone error={error} />}
        <div className="UploadDocumentProcessingButton">
          <Button onClick={handleClose}>{error || numberUploaded === files.length ? 'Close' : 'Cancel'}</Button>
        </div>
      </div>
    </SlideInFromRight>
  );
};
