import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Icon, useOutsideClick, useAsync } from '@hometap/htco-components';
import { Link } from 'react-router-dom';
import { Transition, TransitionStatus } from 'react-transition-group';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';
import sendMagicLink from 'data/sendMagicLink';
import { canSendMagicLink } from 'userRoles';
import useCurrentUser from 'hooks/useCurrentUser';
import './ActionsTooltip.scss';
import {
  MagicLinkState,
  MagicLinkStateValue,
  GetLinkContentsProps,
  EmailElementProps,
  PhoneElementProps,
  LinkElementProps,
  EditElementProps,
  SendMagicLinkElementProps,
  getEnabledActionProps,
  ActionsTooltipProps,
} from './types/types';
import { UseCurrentUser } from 'hooks/types/types';

const COPY_ICON = {
  copy: {
    iconName: 'icon-copy',
    text: 'Copy',
  },
  check: {
    iconName: 'icon-check-mdc',
    text: 'Copied',
  },
};

const emailContents = (
  <>
    <Icon name="icon-email" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">Email</span>
  </>
);
const phoneContents = (
  <>
    <Icon name="icon-phone" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">Call</span>
  </>
);

const getLinkContents = ({ name }: GetLinkContentsProps) => (
  <>
    <Icon name="icon-link" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">{name || 'Visit URL'}</span>
  </>
);
const editContents = (
  <>
    <Icon name="icon-edit" size="sm" className="ActionTooltipIconColor" />
    <span className="ActionsTooltipButtonText">Edit</span>
  </>
);

const EmailElement = ({ emailAddress, onClose }: EmailElementProps) => {
  const emailLink = `mailto:${emailAddress}`;
  const handleClick = () => {
    onClose();
    window.location.href = emailLink;
  };
  return (
    <div className="ActionsTooltipButtonContainer ActionsTooltipEditButtonContainer" onClick={handleClick}>
      {emailContents}
    </div>
  );
};

const PhoneElement = ({ phoneNumber, onClose }: PhoneElementProps) => {
  const phoneLink = `tel:${phoneNumber}`;
  const handleClick = () => {
    onClose();
    window.location.href = phoneLink;
  };
  return (
    <div className="ActionsTooltipButtonContainer ActionsTooltipEditButtonContainer" onClick={handleClick}>
      {phoneContents}
    </div>
  );
};

const LinkElement = ({ toLink, name }: LinkElementProps) => {
  const isExternalLink = toLink.startsWith('http');
  let linkElement;
  const linkClassNames = 'ActionsTooltipButtonContainer ActionsTooltipLinkButtonContainer';
  const linkContents = getLinkContents({ name });

  if (isExternalLink) {
    linkElement = (
      <a className={linkClassNames} href={toLink} target="_blank" rel="'noopener noreferrer">
        {linkContents}
      </a>
    );
  } else {
    linkElement = (
      <Link className={linkClassNames} to={toLink}>
        {linkContents}
      </Link>
    );
  }
  return linkElement;
};

const EditElement = ({ onEdit, onClose, value }: EditElementProps) => {
  const isEditLink = typeof onEdit === 'string';
  const isEditAction = typeof onEdit === 'function';

  const handleEditClick = () => {
    isEditAction && onEdit(value);
    onClose();
  };

  let editElement;
  const editClassNames = 'ActionsTooltipButtonContainer ActionsTooltipEditButtonContainer';
  if (isEditLink && onEdit.startsWith('http')) {
    editElement = (
      <a className={editClassNames} href={onEdit} target="_blank" rel="'noopener noreferrer">
        {editContents}
      </a>
    );
  } else if (isEditLink) {
    editElement = (
      <Link className={editClassNames} to={onEdit}>
        {editContents}
      </Link>
    );
  } else {
    editElement = (
      <div className={editClassNames} onClick={handleEditClick}>
        {editContents}
      </div>
    );
  }
  return editElement;
};

const SendMagicLinkElement = ({ onClick, state }: SendMagicLinkElementProps) => {
  const iconName = {
    idle: 'icon-sparkles',
    loading: 'icon-sparkles',
    error: 'icon-error',
    success: 'icon-check-mdc',
  };
  const iconText = {
    idle: 'Send magic link',
    loading: 'Sending...',
    error: 'Error',
    success: 'Sent',
  };
  return (
    <div
      className="ActionsTooltipButtonContainer ActionsTooltipMagicLinkButtonContainer"
      onClick={() => (state === MagicLinkState.Idle ? onClick() : false)}
    >
      <Icon name={iconName[state]} size="sm" className="ActionTooltipIconColor" />
      <span className="ActionsTooltipButtonText">{iconText[state]}</span>
    </div>
  );
};

const getEnabledAction = ({ actions, actionType }: getEnabledActionProps) =>
  actions?.find(action => action.actionType === actionType);

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ActionsTooltip = ({
  className,
  value,
  actions = [{ actionType: ActionTooltipOptions.Copy }],
  transitionTime = 600,
  children,
}: ActionsTooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [copyIcon, setCopyIcon] = useState(COPY_ICON.copy);
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const [magicLinkState, setMagicLinkState] = useState<MagicLinkState>(MagicLinkState.Idle);
  const { user } = useCurrentUser() as UseCurrentUser;

  const {
    loading: sendMagicLinkLoading,
    error: sendMagicLinkError,
    execute: executeSendMagicLink,
    results: sendMagicLinkResults,
  } = useAsync(sendMagicLink);

  useEffect(() => {
    const magicLinkStateMap: Record<MagicLinkState, MagicLinkStateValue> = {
      [MagicLinkState.Error]: sendMagicLinkError,
      [MagicLinkState.Loading]: sendMagicLinkLoading,
      [MagicLinkState.Success]: sendMagicLinkResults,
      [MagicLinkState.Idle]: null,
    };

    const newMagicLinkState =
      (Object.keys(magicLinkStateMap) as MagicLinkState[]).find(state => magicLinkStateMap[state]) ||
      MagicLinkState.Idle;

    setMagicLinkState(newMagicLinkState);
  }, [sendMagicLinkLoading, sendMagicLinkError, sendMagicLinkResults]);

  const hasActions = !!actions.length;
  const handleClose = () => setShouldShowTooltip(false);
  const showToolTip = () => hasActions && !shouldShowTooltip && setShouldShowTooltip(true);

  useOutsideClick(ref.current, handleClose);

  const email = getEnabledAction({ actions, actionType: ActionTooltipOptions.Email });
  const phone = getEnabledAction({ actions, actionType: ActionTooltipOptions.Phone });
  const link = getEnabledAction({ actions, actionType: ActionTooltipOptions.Link });
  const copy = getEnabledAction({ actions, actionType: ActionTooltipOptions.Copy });
  const edit = getEnabledAction({ actions, actionType: ActionTooltipOptions.Edit });
  const magicLink = getEnabledAction({ actions, actionType: ActionTooltipOptions.MagicLink });

  const isEmailEnabled = !!email;
  const isPhoneEnabled = !!phone;
  const isLinkEnabled = !!link;
  const isCopyEnabled = !!copy;
  const isEditEnabled = !!edit && edit?.onEdit;
  const isMagicLinkEnabled = !!magicLink && canSendMagicLink(user);

  function handleDataCopy() {
    const textToCopy = String(copy?.value || value || '');
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      setCopyIcon(COPY_ICON.check);
      setTimeout(() => {
        handleClose();
      }, transitionTime);
    }
  }

  async function handleSendMagicLink() {
    const emailToSend = magicLink?.value || value;

    if (emailToSend && magicLink) {
      await executeSendMagicLink({
        email: emailToSend,
        trackId: magicLink.trackId,
        isCoapplicant: !!magicLink.isCoapplicant,
      });

      setTimeout(() => {
        handleClose();
      }, transitionTime);
    }
  }

  const defaultStyle = {
    transition: `opacity ${transitionTime}ms ease-out`,
    opacity: 0,
  };

  return (
    <div ref={ref} className={cx('ActionTooltip', className, { hasActions })} onClick={showToolTip}>
      {/* @ts-ignore */}
      <Transition
        unmountOnExit
        nodeRef={ref}
        in={shouldShowTooltip}
        timeout={transitionTime}
        onExited={() => {
          setCopyIcon(COPY_ICON.copy);
          setMagicLinkState(MagicLinkState.Idle);
        }}
      >
        {(state: TransitionStatus) => (
          <div
            className="ActionsTooltipContainer"
            style={{ ...defaultStyle, ...transitionStyles[state as keyof typeof transitionStyles] }}
          >
            <div className="ActionsTooltipButtonsContainer">
              {isEmailEnabled && <EmailElement onClose={handleClose} emailAddress={email.emailAddress!} />}
              {isPhoneEnabled && <PhoneElement onClose={handleClose} phoneNumber={phone.phoneNumber!} />}
              {isLinkEnabled && <LinkElement toLink={link.toLink!} name={link.name!} />}
              {isMagicLinkEnabled && <SendMagicLinkElement onClick={handleSendMagicLink} state={magicLinkState} />}
              {isCopyEnabled && (
                <div
                  className="ActionsTooltipButtonContainer ActionsTooltipCopyButtonContainer"
                  onClick={handleDataCopy}
                >
                  <Icon name={copyIcon.iconName} size="sm" className="ActionTooltipIconColor" />
                  <span className="ActionsTooltipButtonText">{copyIcon.text}</span>
                </div>
              )}
              {isEditEnabled && <EditElement onClose={handleClose} onEdit={edit.onEdit!} value={String(value)} />}
            </div>
          </div>
        )}
      </Transition>
      <span>{children}</span>
    </div>
  );
};

export default ActionsTooltip;
