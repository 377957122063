import { gql } from '@apollo/client';

export const GET_UW_CALCULATOR_TRACK_DATA = gql`
  query GetUWCalculatorTrackData($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      identifier
      friendlyId
      fullAddress
      totalPaydown
      principalPaydown
      feePaydown
      totalDebtOnHome
      startingEquityPercent
      inquiry {
        mortgageBalance
      }
      activeOpportunityRecord {
        url
      }
      incomeWeightedRiskBand {
        value
      }
      product {
        name
        termYears
      }
      beginningHomeValuation {
        identifier
        value
      }
      relevantHomeValuation {
        identifier
        value
      }
      # Rental Property Eligibility
      home {
        identifier
        residenceType
      }
      debtServiceCoverageRatioInfo {
        dscrValue
        dscrValueStatus
      }
      compositeFicoData {
        value
        errorMessage
      }
      htLienPosition
      rb6Criteria
      highestSingleYearDelinquentTaxes
    }
  }
`;

export const GET_INVESTMENT_CALCULATOR_TRACK_DATA = gql`
  query GetUWCalculatorTrackData($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      identifier
      friendlyId
      fullAddress
      totalPaydown
      principalPaydown
      feePaydown
      totalDebtOnHome
      startingEquityPercent
      inquiry {
        mortgageBalance
      }
      activeOpportunityRecord {
        url
      }
      incomeWeightedRiskBand {
        value
      }
      product {
        name
        termYears
      }
      beginningHomeValuation {
        identifier
        value
      }
      relevantHomeValuation {
        identifier
        value
      }
    }
  }
`;
