import { gql } from '@apollo/client';

export const COMPLETE_DOC_UPLOAD_TASK = gql`
  mutation CompleteDocUploadTask(
    $taskId: IdentifierType!
    $resolution: TaskResolutionEnum!
    $uploadedDocIds: [IdentifierType!]
  ) {
    completeDocUploadTask(taskId: $taskId, resolution: $resolution, uploadedDocIds: $uploadedDocIds) {
      task {
        taskStatus
        identifier
        isOpen
        id
      }
    }
  }
`;

export const COMPLETE_ACKNOWLEDGEMENT_TASK = gql`
  mutation CompleteAcknowledgementTask($taskId: IdentifierType!, $resolution: TaskResolutionEnum!) {
    completeAcknowledgementTask(taskId: $taskId, resolution: $resolution) {
      task {
        taskStatus
        identifier
        isOpen
        id
      }
    }
  }
`;
