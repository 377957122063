import { useState } from 'react';
import { Modal, Button, NumberInput, useForm } from '@hometap/htco-components';
import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';
import { RiskBandCriteriaPopupProps } from '../types/types';
import { useMutation } from '@apollo/client';
import { UPDATE_RB6_CRITERIA } from '../queries';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import { showNotification, TOAST_TYPE } from 'utils/toasts';

const RiskBandCriteriaPopup = ({ rb6Criteria, isEditable, trackId }: RiskBandCriteriaPopupProps) => {
  const { registerField, handleSubmit } = useForm();
  const [showEditModal, setShowEditModal] = useState(false);
  const [initialValue, setInitialValue] = useState(rb6Criteria);

  const closeModal = () => {
    setShowEditModal(false);
  };

  const openModal = () => setShowEditModal(true);

  const [updateRB6Critera, { loading }] = useMutation(UPDATE_RB6_CRITERIA, {
    onCompleted: result => {
      if (result.updateRb6Criteria.error) {
        setInitialValue(rb6Criteria);
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save',
          description: `Could not update the value, ${result.updateRb6Criteria.error}`,
        });
      } else {
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Underwriting Field Updated',
          description: 'Risk Band 6 Criteria Updated',
        });
      }
    },
    onError: e => {
      setInitialValue(rb6Criteria);
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to save',
        description: `Could not update the value please try again ${e}`,
      });
    },
  });

  const onSubmit = (data: { rb6Criteria: number }) => {
    setInitialValue(data.rb6Criteria);
    updateRB6Critera({
      variables: {
        trackId: trackId,
        rb6Criteria: data.rb6Criteria,
      },
    });
    setShowEditModal(false);
  };

  let formatedFieldValue;

  if (initialValue === null) {
    formatedFieldValue = isEditable ? 'Add value' : '-';
  } else {
    formatedFieldValue = initialValue;
  }

  return (
    <>
      <LoadingContainer isLoading={loading}>
        {isEditable ? (
          <ActionsTooltip
            value={formatedFieldValue}
            actions={[
              {
                actionType: ActionTooltipOptions.Edit,
                onEdit: () => openModal(),
              },
            ]}
          >
            {formatedFieldValue}
          </ActionsTooltip>
        ) : (
          formatedFieldValue
        )}

        {showEditModal && (
          <Modal className="UnderwritingModal" open={showEditModal} onClose={closeModal} width={536}>
            <form className="UnderwritingModalDetails" onSubmit={handleSubmit(onSubmit)}>
              <h1 className="UnderwritingModalTitle">Edit</h1>

              <div className="UnderwritingModalSubTitle">Enter new value for risk band 6 criteria</div>

              <NumberInput
                type="integer"
                max={20}
                label="Risk band 6 criteria"
                required
                className="m-0"
                {...registerField('rb6Criteria')}
              />

              <Button type="submit" className="UnderwritingModalButton" size="large">
                Save
              </Button>
            </form>
          </Modal>
        )}
      </LoadingContainer>
    </>
  );
};

export default RiskBandCriteriaPopup;
