import React from 'react';
import cx from 'classnames';
import { Button, Icon, Loader, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';

import './ButtonBar.scss';
import { Link, useParams } from 'react-router-dom';

/**
 * Buttons at the top of the documents page
 * @param {object} documents: documents object from calling /tracks/:trackId/documents
 * @param {callback} uploadClick: callback when user clicks 'upload' button
 * @param {callback} togglePin: callback to be called when user clicks pin/unpin
 * @param {callback} deleteClick: callback when user clicks 'delete' button
 * @param {callback} mergeClick: callback when user clicks 'Combine into single PDF' button
 * @param {callback} downloadClick: callback when user clicks 'Download' button
 * @param {boolean} loading
 * @returns
 */

export const ButtonBar = ({
  documents,
  uploadClick,
  togglePin,
  deleteClick,
  loading,
  mergeClick,
  downloadClick,
  downloadLoading,
  documentSetClick,
}) => {
  const unmergeableDocumentKinds = [
    'title_documents:search_package',
    'title_documents:property_report',
    'certificates:tax_certification',
  ];

  const checkedDocuments = documents ? Object.entries(documents)?.filter(doc => doc[1].checked) : [];

  const unmergeableCheckedDocuments = checkedDocuments.filter(doc => unmergeableDocumentKinds.includes(doc[1].kind));
  const unmergeableDocumentsCount = unmergeableCheckedDocuments.length;
  const checkedDocumentIds =
    unmergeableDocumentsCount <= 1
      ? checkedDocuments.map(doc => doc[0])
      : checkedDocuments.filter(doc => !unmergeableDocumentKinds.includes(doc[1].kind)).map(doc => doc[0]);
  const isMergeableCheckedDocuments = checkedDocumentIds.length > 1 && unmergeableDocumentsCount <= 1;

  const { trackId } = useParams();

  // if all documents are pinned => unpin
  // if all documents are unpinned => pin
  // if some documents are pinned/unpinned => default to pin
  // true = Pin, false = Unpin
  const pinOrUnpin = !Object.values(documents ?? {})
    .filter(doc => doc.checked)
    .every(doc => doc.pinned === true);

  const handleClick = () => {
    togglePin(checkedDocumentIds, pinOrUnpin);
  };

  /**
   * determines if any of the documents have been checked
   * @returns {boolean}
   */
  const shouldDisplay =
    documents &&
    Object.values(documents)
      .map(doc => doc.checked)
      .includes(true);

  const handleClickDeleteButton = () => {
    deleteClick(checkedDocumentIds);
  };

  const handleMergeButton = () => {
    mergeClick(checkedDocumentIds);
  };

  const handleClickDownloadButton = () => {
    const documentsToDownload = Object.entries(documents)
      ?.filter(doc => doc[1].checked)
      .map(doc => doc[1]);
    downloadClick(documentsToDownload);
  };

  return (
    <div className="ButtonBar">
      <span className="ButtonBarLeftContainer">
        <Button data-test-id="UploadButton" onClick={uploadClick} size="small">
          Upload
        </Button>
        <Button size="small" theme="secondary" onClick={documentSetClick} className="ButtonBarCreateDocumentSetButton">
          Create Document Set
        </Button>
        <span className={cx('ButtonBarContainer', { shouldDisplay })}>
          <Button
            className={cx({ downloadLoading })}
            theme="secondary"
            size="small"
            onClick={handleClickDownloadButton}
            disabled={loading}
          >
            {downloadLoading ? <Loader type="spinner" /> : 'Download'}
          </Button>
          <Button theme="secondary" size="small" onClick={handleClickDeleteButton} disabled={loading}>
            Delete
          </Button>
          <MenuDropdown className="ButtonBarDropDown" disabled={loading}>
            <MenuDropdownItem onClick={handleClick}>
              <Icon name="icon-pin" />
              {pinOrUnpin ? 'Pin' : 'Unpin'}
            </MenuDropdownItem>
            <MenuDropdownItem
              disabled
              className={cx({
                MenuDropdownItemDisabled: !isMergeableCheckedDocuments,
              })}
              onClick={handleMergeButton}
            >
              <Icon name="icon-file-pdf" />
              Merge into single PDF
            </MenuDropdownItem>
          </MenuDropdown>
        </span>
      </span>
      <span>
        <Link to={`/tracks/${trackId}/documents/document-set-request-log`}>Document set request log</Link>
      </span>
    </div>
  );
};
