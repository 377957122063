import React from 'react';
import { groupBy } from 'lodash';

import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import TaskDetailContentSectionWrapper from '../../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';
import { formatFraudGuardData } from './formatFraudGuardData';

import './BackgroundCheck.scss';
import { Button, Icon, LoadingPlaceholder, Table } from '@hometap/htco-components';
import { getDateTimeFormat } from 'utils/dates';
import { getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { GET_BACKGROUND_CHECK_DATA } from './getBackgroundCheckData';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';

const fraudGuardRequestDescriptions = {
  PLACED: 'PDF Request Sent',
  PENDING: 'Data Sent To FraudGuard',
  COMPLETED: 'PDF Request Successful',
  FAILED: 'PDF Request Unsuccessful',
};

const FraudGuardRequestTable = ({ requests }) => {
  const columns = [
    { label: 'Request Date and Time', key: 'requestDate' },
    { label: 'Status', key: 'status' },
    { label: 'Description (last event)', key: 'description' },
  ];

  const data = requests.map((request, i) => ({
    requestDate: getDateTimeFormat(request.createdAt, 'MMM DD, YYYY'),
    id: `fg-request-${i}`,
    description: fraudGuardRequestDescriptions[request.status] || 'Unknown',
    status: request?.status.toLowerCase() || '-',
  }));

  return <Table data={data} columns={columns} compact className="BackgroundCheckFraudGuardTable" />;
};

const BackgroundCheck = () => {
  const { loading, data = {} } = useSpecificContentQuery(GET_BACKGROUND_CHECK_DATA);
  const { track } = data;
  const { documents = [] } = track || {};
  const fraudGuardData = track && formatFraudGuardData(track);

  const sortedTrackDocs = [...documents].sort((a, b) => b.isPinned - a.isPinned);
  const groupedDocuments = groupBy(sortedTrackDocs, 'kind.label');
  const documentsList = !!Object.entries(groupedDocuments).length ? (
    Object.entries(groupedDocuments).map(([label, documents], i) => {
      return (
        <ul className="TaskDetailList BackgroundCheckDocumentsList" key={`${label}-${i}`}>
          <span>{label}</span>
          {documents.map(document => {
            const { getDocumentViewUrl } = getTrackDetailPageUrls(track.identifier);
            return (
              <li key={document.identifier}>
                <span className="BackgroundCheckPinIcon">{document.isPinned && <Icon name="icon-pin-fill" />} </span>
                <a rel="noreferrer noopener" target="_blank" href={getDocumentViewUrl(document.identifier)}>
                  {document.filename}
                </a>
              </li>
            );
          })}
        </ul>
      );
    })
  ) : (
    <ul className="TaskDetailList BackgroundCheckDocumentsList">No documents to show</ul>
  );

  return (
    <div className="TaskDetailContent">
      <TaskDetailContentSectionWrapper className="BackgroundCheckFGRequestsContainer" heading="Request Status History">
        <LoadingPlaceholder isLoading={loading}>
          {({ loadingClass }) => {
            return (
              <div className={loadingClass}>
                {!loading && (
                  <small className="BackgroundCheckRequestInstructions">
                    Refresh the page to get the latest status.
                  </small>
                )}
                {track?.fraudGuardData ? (
                  <FraudGuardRequestTable requests={track.fraudGuardData} />
                ) : (
                  <div>No Request History available at the moment</div>
                )}
              </div>
            );
          }}
        </LoadingPlaceholder>
      </TaskDetailContentSectionWrapper>
      <TaskDetailContentSectionWrapper heading="Documents">
        <LoadingPlaceholder isLoading={loading}>
          {({ loadingClass }) => {
            return <div className={loadingClass}>{documentsList}</div>;
          }}
        </LoadingPlaceholder>
      </TaskDetailContentSectionWrapper>
      <div className="BackgroundCheckButtonRow">
        <ActionsTooltip
          value={JSON.stringify(fraudGuardData)}
          className="BackgroundCheckCopyContainer"
          actions={[{ actionType: ActionTooltipOptions.Copy }]}
        >
          FraudGuard Data
        </ActionsTooltip>

        <Button
          theme="link"
          target="_blank"
          rel="noreferrer noopener"
          href="https://login.firstam.com/firstamericanidentity.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_fa_eidv2_signin&client_id=7c346c4a-8ad6-46c7-89a7-e0806748a59e&redirect_uri=https%3A%2F%2Feagleid.fraudguard.firstam.mortgage&response_type=id_token&scope=openid&state=OpenIdConnect.AuthenticationProperties%3DV6vJx2bSUmCurqvpy8pzdzzs_dvfmojKR2zWtEE31NR2WWyiSAvVZHqP0vImjQBZ4yHfGKMBVi7_p9K8feG1o002mPBaA0JvxeVlgqCa-eN7m462vhuIC3pCvWceRxjeu_CyebqvTc97KWxq2FdENYRlc5HxsIXUZzOTIJ9a1e7fuB_-LAP_nvZUudiG1Dm9SoZgCsPrTCkWtV7d05VDCus6I188AueWc6_Z84PnRonS8ucxM_cu3WoETCKiOjWjH5oy1-BPwyvBD6knJUpGM0PgSvjRrreUjwCSL9o5WFUNpfhL&response_mode=form_post&nonce=638537219729536875.NjA5NjZkMmMtNWUyNi00ZWE3LTk0MjctZTdmYmMwNzQzMmE3MmVhNTM3YTctNTZiNC00YWFmLTllMGYtMmMxNjVlMGMwYzgx&post_logout_redirect_uri=https%3A%2F%2Feagleid.fraudguard.firstam.mortgage"
        >
          Navigate to FraudGuard
        </Button>
      </div>
    </div>
  );
};

export default BackgroundCheck;
