import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { LiensAndPaydownsForm } from './components/LiensAndPaydownsForm';
import DemoButton from 'components/DemoButton/DemoButton';
import './LiensAndPaydownsController.scss';
import { SECTION_ALERT_TYPES, useFormsForAppReviewSection } from '../../hooks/useSpecificContentQuery';
import useTrackDocuments from 'apps/track-details/Documents/hooks/useTrackDocuments';
import { Button, MuiSelect, useAsync } from '@hometap/htco-components';
import { useLienSections } from './hooks/useLienSections';
import { usePaydownForm } from './hooks/usePaydownForm';
import { useMutation, useQuery } from '@apollo/client';
import { SYNC_TOTAL_LIEN_BALANCE_TO_SALESFORCE } from '../../data/mutations/syncTotalLienBalanceToSalesforce';
import {
  getInitialSectionsLiens,
  getIsNewId,
  getLienCurrentBalance,
  getNewLienSection,
  getParsedValue,
  getSummaryLiensParams,
  stringToFloat,
  updateArrayByKey,
} from './utils/liens';
import { getNewPaydown } from './components/PaydownController/helpers';
import useConfigurations from 'hooks/useConfigurations';
import { useScrollToElement } from 'hooks/useScrollToElement';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { DELETE_LIEN } from 'apps/track-details/tasks/data/mutations';
import { useAppReviewFormDeletionV1 } from '../../hooks/useAppReviewFormDeletionV1';
import { validateDateNotInThePast, validateDateNotMoreThanNDaysInPast } from 'utils/validators';
import { createLien } from '../../../../../data/trackRequest';
import env from 'utils/env';
import { GET_CALCULATED_TRACK_DATA } from 'apps/track-details/ApplicationReview/data/queries/getCalculatedTrackData';

const LIENS_AND_PAYDOWNS_ANCHOR_ID = 'mortgage-liens-section';

// Function for determining whether a lien is missing a payoff.
export function getIsRequiredPayoffMissing(payoffRequiredConditions, lien, isDbRecord) {
  const missingPayoff = isDbRecord ? lien?.paydown === null : lien?.paydownId === undefined;
  const lienProps = isDbRecord ? lien : lien?.formData;
  const lienPayoffRequirement = payoffRequiredConditions.find(
    requiredKind => requiredKind.kind === lienProps?.kind?.toLowerCase(),
  );

  // if no lien payoff requirement is found, return false.
  if (!lienPayoffRequirement) return false;

  if (lienPayoffRequirement.conditions !== null) {
    for (const key in lienPayoffRequirement.conditions) {
      const { propertyName, value } = lienPayoffRequirement.conditions[key];

      if (!lienProps.hasOwnProperty(propertyName) || !missingPayoff) {
        return false;
      }

      // conditions contain JSON values and lienProps[propertyName] can be a JSON value
      // we parse and compare them
      if (getParsedValue(value) !== getParsedValue(lienProps[propertyName])) {
        return false;
      }
    }

    return true;
  }

  return missingPayoff;
}

// Function for determining whether a paydown on a lien does not match its current principal balance.
const isLienBalanceUnequal = (lien, lienKindsPrincipalAmountBalance) => {
  if (lien && lien?.paydown) {
    const {
      paydown: { isPayoff, principalPaydownAmount },
    } = lien;

    const lienCurrentBalance = getLienCurrentBalance(lien, lienKindsPrincipalAmountBalance);
    const hasLienBalance = lienCurrentBalance || lienCurrentBalance === 0;
    const shouldValidatePOPrincipalAmount =
      isPayoff === 'true' && (principalPaydownAmount || principalPaydownAmount === 0);

    if (shouldValidatePOPrincipalAmount && hasLienBalance) {
      // The Lien Balance is compared with the value entered in the Principal Paydown field.
      return lien.balanceAmount !== stringToFloat(principalPaydownAmount);
    }
  }
  return false;
};

export const LiensAndPaydownsController = ({ track, editDisabled, refetch }) => {
  const { trackId } = useParams();
  const { liens } = track;
  const [demoLienKind, setDemoLienKind] = useState(track.liensKindOptions[0].value);
  const { lienKindToDocKind, lienKindsRequiringPayoff, lienKindsPrincipalAmountBalance } = useConfigurations();

  const { setShouldScroll } = useScrollToElement(LIENS_AND_PAYDOWNS_ANCHOR_ID);
  const [syncTotalLienBalanceToSalesforce] = useMutation(SYNC_TOTAL_LIEN_BALANCE_TO_SALESFORCE);
  const afterPublishSection = useCallback(async () => {
    await syncTotalLienBalanceToSalesforce({ variables: { trackId } });
  }, [trackId, syncTotalLienBalanceToSalesforce]);
  const { refetch: refetchCalculatedTrackData } = useQuery(GET_CALCULATED_TRACK_DATA, {
    variables: { trackId },
  });

  const [deleteLienMutation] = useMutation(DELETE_LIEN);

  const homeValue = track.beginningHomeValuation?.value ?? track.relevantHomeValuation?.value;

  const SECTION_ALERT_DEFINITIONS_IN_PRIORITY_ORDER = [
    {
      type: SECTION_ALERT_TYPES.ERROR,
      message: 'Please correct the form errors in order to publish changes',
      getTriggeredDetails: idToDBRecordAndForm => {
        const triggeringIds = Object.entries(idToDBRecordAndForm)
          // TODO https://hometap.atlassian.net/browse/EH-533 Rename newLien -> isDraft.
          // Check that form exists, is not deleted, is not valid
          // showTriggeredPublishErrors necessary to check if we are in a different section and there are new lines that contain errors
          .filter(([_, { form }]) =>
            form?.formData
              ? !form.isValidForm && (form.showSubmitErrors || form.showTriggeredPublishErrors)
              : form?.showTriggeredTypeError,
          )
          // TODO https://hometap.atlassian.net/browse/EH-548 Rename form.lienId to form.parentId
          // Get the id of the parent form if it exists, otherwise use the form id. The parent form has the same identifier as the tab selector
          .map(([id, { form }]) => form.lienId ?? id);
        return {
          isTriggered: triggeringIds.length > 0,
          triggeringIds,
        };
      },
    },
    {
      type: SECTION_ALERT_TYPES.ERROR,
      getTriggeredDetails: idToDBRecordAndForm => {
        if (homeValue === undefined) {
          return {
            isTriggered: false,
          };
        }
        const lienIdToAmount = Object.entries(idToDBRecordAndForm).reduce(
          (acc, [_, { form, dbRecord, getCurrentValue, parentId }]) => {
            const lienId = form?.lienId ?? parentId;
            if (!lienId) {
              return acc;
            }
            let paydownAmount = 0;
            const principalPaydownAmount = getCurrentValue('principalPaydownAmount');
            if (principalPaydownAmount) {
              paydownAmount += Number(principalPaydownAmount);
            }
            const feePaydownAmount = getCurrentValue('feePaydownAmount');
            if (feePaydownAmount) {
              paydownAmount += Number(feePaydownAmount);
            }
            if (paydownAmount) {
              // ONLY paydowns will have a paydownAmount. Liens will not.
              acc[lienId] = paydownAmount;
            }
            return acc;
          },
          {},
        );
        const triggeringIds = Object.entries(lienIdToAmount)
          .filter(([_, amount]) => amount > homeValue)
          .map(([lienId, _]) => lienId);
        if (triggeringIds.length > 0) {
          return {
            isTriggered: triggeringIds.length > 0,
            triggeringIds,
            message: 'One or more PO/PD exceeds the Home Value',
          };
        }

        const totalPaydownAmount = Object.values(lienIdToAmount).reduce((acc, amount) => acc + amount, 0);
        return {
          isTriggered: totalPaydownAmount > homeValue,
          message: 'Total PO/PD exceeds the Home Value',
        };
      },
    },
    {
      // lien section specific definition for raising alert when a track is flagged for dq
      type: SECTION_ALERT_TYPES.ERROR,
      message: 'Track flagged for DQ.',
      getTriggeredDetails: idToDBRecordAndForm => {
        const triggeringIds = Object.entries(idToDBRecordAndForm)
          .filter(([_, { dbRecord, form }]) => {
            if (form?.formData !== undefined) {
              return form.formData?.isMoreThanOneMonthPastDue === 'true' && form.formData?.isForbearance === 'false';
            }
            return dbRecord?.isMoreThanOneMonthPastDue && !dbRecord.isForbearance;
          })
          .map(([id, _]) => id);
        return {
          isTriggered: triggeringIds.length > 0,
          triggeringIds,
        };
      },
    },
    {
      // lien section specific definition for raising an alert when a required payoff is missing
      type: SECTION_ALERT_TYPES.WARNING,
      message: 'One or more liens may require a payoff.',
      getTriggeredDetails: idToDBRecordAndForm => {
        const triggeringIds = Object.entries(idToDBRecordAndForm)
          .filter(([_, { dbRecord, form }]) => {
            return form?.formData === undefined
              ? getIsRequiredPayoffMissing(lienKindsRequiringPayoff, dbRecord, true)
              : getIsRequiredPayoffMissing(lienKindsRequiringPayoff, form, false);
          })
          .map(([id, _]) => id);
        return {
          isTriggered: triggeringIds.length > 0,
          triggeringIds,
        };
      },
    },
    {
      // lien section specific definition for raising an alert when a paydown does not match a lien's current principal balance
      type: SECTION_ALERT_TYPES.WARNING,
      message: 'One or more Payoffs are insufficient to cover Principal balance.',
      getTriggeredDetails: idToDBRecordAndForm => {
        const triggeringIds = Object.entries(idToDBRecordAndForm)
          .filter(([_, { dbRecord, form }]) => {
            const lienId = dbRecord?.identifier || form?.formData?.identifier;
            return isLienBalanceUnequal(
              lienSections.find(section => section?.identifier === lienId),
              lienKindsPrincipalAmountBalance,
            );
          })
          .map(([id, _]) => id);
        return {
          isTriggered: triggeringIds.length > 0,
          triggeringIds,
        };
      },
    },
    {
      type: SECTION_ALERT_TYPES.WARNING,
      message: 'One or more PO/PD has a good through date in the past.',
      getTriggeredDetails: idToDBRecordAndForm => {
        const triggeringIds = Object.entries(idToDBRecordAndForm)
          .filter(([_, { childDbRecord, form }]) => {
            const goodThroughDate = form ? form.formData?.goodThroughDate : childDbRecord?.goodThroughDate;
            return validateDateNotInThePast(goodThroughDate);
          })
          .map(([_, { dbRecord, form }]) => dbRecord?.identifier || form?.lienId);
        return {
          isTriggered: triggeringIds.length > 0,
          triggeringIds,
        };
      },
    },
    {
      type: SECTION_ALERT_TYPES.WARNING,
      message: 'To-do recommended.',
      getTriggeredDetails: idToDBRecordAndForm => {
        const triggeringIds = Object.entries(idToDBRecordAndForm)
          .filter(([_, { getCurrentValue }]) => {
            const asOfDate = getCurrentValue('asOfDate');
            return validateDateNotMoreThanNDaysInPast(asOfDate);
          })
          .map(([id, _]) => id);
        return {
          isTriggered: triggeringIds.length > 0,
          triggeringIds,
        };
      },
    },
  ];

  const {
    isSaving,
    isChanged,
    confirmSection,
    cancelSectionChanges,
    savePrevFormDataById,
    deleteSectionLienPaydown,
    getHighestPrioritySectionAlert,
    setSectionFormsMap,
    triggerPublishErrors,
  } = useFormsForAppReviewSection({
    afterPublishSection,
    sectionAlertDefinitionsInPriorityOrder: SECTION_ALERT_DEFINITIONS_IN_PRIORITY_ORDER,
  });

  const memoLiens = useMemo(() => {
    return getInitialSectionsLiens(liens, track.liensKindOptions);
  }, [liens, track.liensKindOptions]);

  const [lienPositionError, setLienPositionError] = useState({});
  const [sections, setSections] = useState(memoLiens); // i.e. tabs
  const [currentSection, setCurrentSection] = useState((liens && liens[0]) || {}); // i.e. current tab
  // keep liens without deleted paydowns until the page is refreshed and that the logic for cancelling changes works
  const [isLienKindChanged, setIsLienKindChanged] = useState(false);
  const [lienReportFieldsTouched, setLienReportFieldsTouched] = useState({});

  const deleteExistingLien = async lienId => {
    try {
      await deleteLienMutation({ variables: { lienId } });
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'Lien successfully deleted.',
      });
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to delete the lien',
        description: error.message || 'An error occurred while deleting the lien.',
      });
    }
  };

  const { nodesWithoutDeleted, setNodesWithoutDeleted, deleteItem } = useAppReviewFormDeletionV1({
    sections,
    setSections,
    currentSection,
    setCurrentSection,
    setReportFieldsTouched: setLienReportFieldsTouched,
    memoNodes: memoLiens,
    deleteExistingDBRecord: deleteExistingLien,
    draftKey: 'newLien',
  });

  // TODO https://hometap.atlassian.net/browse/EH-569 Consolidate App Review Section Tab related code into a single hook with settings
  useEffect(() => {
    setSections(memoLiens);
    setCurrentSection(currentSection => {
      const updatedCurrentSection = memoLiens.find(node => node.identifier === currentSection.identifier);
      return updatedCurrentSection || (memoLiens && memoLiens[0]) || {};
    });
    setNodesWithoutDeleted(memoLiens);
    // Reset the forms in memory when the tabs are updated from the database
    setSectionFormsMap({});
  }, [memoLiens, setSectionFormsMap, setNodesWithoutDeleted]);
  const { deletePaydownForm } = usePaydownForm(currentSection, trackId, currentSection?.identifier);

  const {
    lienSections,
    totalBalance,
    principalPaydown,
    feePaydown,
    totalPaydown,
    htLienPosition: calculatedHtLienPosition,
  } = useLienSections({
    sections,
    lienId: currentSection.identifier,
    paydownId: currentSection.paydown?.identifier,
    liensKindOptions: track.liensKindOptions,
    lienKind: currentSection.kind,
    setLienPositionError: setLienPositionError,
    lienKindsPrincipalAmountBalance,
  });
  const htLienPosition = calculatedHtLienPosition;
  const { historyMortgageLiensUrl } = getApplicationReviewPageUrls(trackId);

  const [prevSectionId, setPrevSectionId] = useState(liens && liens[0]?.identifier);

  const { documents, executeGetTrackDocuments } = useTrackDocuments(trackId);

  // Callback for getting a list of candidate documents on this track that are associated with the given lien's kind
  const getAvailableDocOptions = useCallback(
    (lien, lienKind) => {
      // get the list of documents linked to other lien review objects
      const getLienReviewDocumentsToExclude = () => {
        return nodesWithoutDeleted
          .filter(lienReview => {
            return lienReview.identifier !== lien.identifier;
          })
          .map(lienReview => {
            return lienReview?.document?.identifier;
          });
      };

      const lienDocsToExclude = getLienReviewDocumentsToExclude();
      let candidateDocuments;
      const docKind = lienKindToDocKind[lienKind];
      if (docKind) {
        candidateDocuments = documents.filter(({ id, kind }) => {
          return !lienDocsToExclude.includes(id) && kind === docKind;
        });
      }
      return candidateDocuments?.map(({ id, filename }) => ({ value: id, label: filename })) ?? [];
    },
    [documents, nodesWithoutDeleted, lienKindToDocKind],
  );

  const { execute: createDemoLien } = useAsync(createLien, {
    onSuccess: () => {
      refetch();
      executeGetTrackDocuments(trackId);
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: `created a new demo lien`,
      });
    },
    onError: err => {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to create a lien',
        description: err.message || 'An error occurred while creating lien.',
      });
    },
  });

  const changeCurrentSection = selectorId => {
    if (selectorId !== currentSection.selectorId) {
      const selectedSection = lienSections.find(lien => lien.selectorId === selectorId);
      setPrevSectionId(currentSection.identifier);
      if (currentSection.paydown?.identifier) {
        savePrevFormDataById(currentSection.paydown.identifier);
      }
      setCurrentSection(selectedSection);
      setShouldScroll(true);
    }
  };

  const saveChanges = async () => {
    if (!isSaving) {
      const { hasTriggeredErrors, breakPublish } = triggerPublishErrors(lienReportFieldsTouched);
      if (hasTriggeredErrors || breakPublish) {
        return;
      }

      setIsLienKindChanged(false);
      await confirmSection();
      refetch();
      setLienReportFieldsTouched({});
    }
  };

  const cancelChanges = () => {
    cancelSectionChanges(currentSection.identifier);
    const newSections = getInitialSectionsLiens(nodesWithoutDeleted, track.liensKindOptions);
    setSections(newSections);

    const initialCurrentSection = newSections.find(sections => sections.selectorId === currentSection?.selectorId);
    if (initialCurrentSection) {
      setCurrentSection(initialCurrentSection);
    } else if (newSections.length < 1) setCurrentSection({});
    else setCurrentSection(newSections[0]);
    setIsLienKindChanged(false);
    setLienReportFieldsTouched({});
  };

  const addNewLien = () => {
    setPrevSectionId(currentSection.identifier);
    if (currentSection.paydown?.identifier) {
      savePrevFormDataById(currentSection.paydown.identifier);
    }
    const newSection = getNewLienSection();
    setSections([...lienSections, newSection]);
    setCurrentSection(newSection);
    setShouldScroll(true);
  };

  const addNewPaydown = () => {
    // Check for any liens that are active but have no other fields
    const newPaydown = getNewPaydown();
    setCurrentSection({ ...currentSection, paydown: newPaydown });
    setSections(updateArrayByKey(lienSections, currentSection.identifier, { paydown: newPaydown }));
  };

  const deletePaydown = async ({ lienId, paydownId }) => {
    const removePaydownFromLien = lienId => {
      if (lienId === currentSection.identifier) {
        setCurrentSection({ ...currentSection, paydown: null });
      }
      if (lienId) {
        setSections(updateArrayByKey(lienSections, lienId, { paydown: null }));
      }
    };

    if (getIsNewId(paydownId)) {
      removePaydownFromLien(lienId);
    } else {
      await deleteSectionLienPaydown(paydownId, lienId, deletePaydownForm, () => {
        removePaydownFromLien(lienId);
        setNodesWithoutDeleted(liens => updateArrayByKey(liens, lienId, { paydown: null }));
      });
    }
    refetch();
  };

  const deleteLien = async () => {
    await deleteItem();
    await refetchCalculatedTrackData();
  };

  return (
    <ApplicationReviewSection
      anchorId={LIENS_AND_PAYDOWNS_ANCHOR_ID}
      sectionTitle="Liens and paydowns"
      historyUrl={historyMortgageLiensUrl}
      pickList={lienSections}
      summaryParams={getSummaryLiensParams({
        principalPaydown,
        feePaydown,
        htLienPosition,
        totalBalance,
        totalPaydown,
      })}
      sectionAlert={getHighestPrioritySectionAlert({ dbRecords: nodesWithoutDeleted, childRecordKey: 'paydown' })}
      visibleSummary={!isEmpty(lienSections)}
      selectedId={(currentSection && currentSection.selectorId) || 0}
      onSelect={changeCurrentSection}
      isWithActionBar={!isEmpty(currentSection)}
      actionBarProps={{
        isPrimaryButtonEnabled: !editDisabled,
        isSecondaryButtonEnabled: (isChanged && !isSaving) || nodesWithoutDeleted.length !== lienSections.length,
        isShowPrompt: isChanged || isLienKindChanged,
        isSaving,
        onPrimaryButtonClick: saveChanges,
        onSecondaryButtonClick: cancelChanges,
      }}
      onEdit={null}
      bottomLink={
        <Button theme="link" onClick={addNewLien} disabled={editDisabled}>
          Add a lien
        </Button>
      }
      demoButton={
        !env.isProd() && (
          <div className="TwoItemFormRow DemoLienWrapper">
            <DemoButton
              onClickAction={() => createDemoLien(trackId, { kind: demoLienKind })}
              label="Demo"
              pageLevel={false}
              disabled={editDisabled}
            />
            <MuiSelect
              label="Lien Type"
              options={track.liensKindOptions}
              className="DemoLienSelector"
              disabled={editDisabled}
              theme="outlined"
              value={demoLienKind}
              onChange={setDemoLienKind}
            />
          </div>
        )
      }
    >
      {isEmpty(currentSection) ? (
        <div>There is no mortgage or lien data</div>
      ) : (
        <LiensAndPaydownsForm
          disabled={editDisabled}
          lien={currentSection}
          getAvailableDocOptions={getAvailableDocOptions}
          liensKindOptions={track.liensKindOptions}
          liensMortgageInvestorKindOptions={track.liensMortgageInvestorKindOptions}
          liensRateTypeOptions={track.liensRateTypeOptions}
          prevSectionId={prevSectionId}
          trackId={trackId}
          lienPositionError={lienPositionError}
          setErrorMessage={setLienPositionError}
          addNewPaydown={addNewPaydown}
          deletePaydown={deletePaydown}
          setIsLienKindChanged={setIsLienKindChanged}
          lienReportFieldsTouched={lienReportFieldsTouched}
          setLienReportFieldsTouched={setLienReportFieldsTouched}
          deleteLien={deleteLien}
          homeValue={homeValue}
        />
      )}
    </ApplicationReviewSection>
  );
};
