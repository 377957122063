enum ActionTooltipOptions {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Copy = 'COPY',
  Edit = 'EDIT',
  Link = 'LINK',
  MagicLink = 'MAGIC_LINK',
}

export default ActionTooltipOptions;
