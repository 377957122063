import { gql } from '@apollo/client';

export const UPDATE_RB6_CRITERIA = gql`
  mutation updateRb6Criteria($trackId: IdentifierType!, $rb6Criteria: Int!) {
    updateRb6Criteria(trackId: $trackId, rb6Criteria: $rb6Criteria) {
      ok
      error
      track {
        identifier
        reviewStatus {
          label
          value
        }
      }
    }
  }
`;

export const UPDATE_HIGHEST_SINGLE_YEAR_DELINQUENT_TAXES = gql`
  mutation updateHighestSingleYearDelinquentTaxes(
    $trackId: IdentifierType!
    $highestSingleYearDelinquentTaxes: Decimal!
  ) {
    updateHighestSingleYearDelinquentTaxes(
      trackId: $trackId
      highestSingleYearDelinquentTaxes: $highestSingleYearDelinquentTaxes
    ) {
      ok
      error
      track {
        identifier
        reviewStatus {
          label
          value
        }
      }
    }
  }
`;
