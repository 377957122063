import React from 'react';

export type UnderwritingDetailsProps = {
  trackId: string;
  trackHistoryUrl: string;
  uwRuleVersion: string;
  applicationSpecialist: string;
  ownersEndingEquity: string;
  totalEndingEquity: string;
  hasRentalResidentialType: boolean;
  underwriter: string | null;
  htLienPosition: number | null;
  principalPaydown: number | null;
  totalPaydown: number | null;
  maximumInvestmentAmount: number | null;
  beginnningHomeValuationLabel: string;
  beginnningHomeValuation: number | null;
  lienBalanceTotal: number | null;
  pastDueLienBalanceTotal: number | null;
  currentIncomeWeightedRiskBand: number | null;
  finanalCompositeFico: number | null;
  highestSingleYearDelinquentTaxes: string | null;
  rb6Criteria: number | null;
  trackReviewStatus?: string;
  lossThreshold?: number | null;
  shareUp?: number | null;
  shareDown?: number | null;
  uwDscr?: number | null;
  refetch?: () => void;
};

export type DelinquentTaxesPopupProps = {
  highestSingleYearDelinquentTaxes: string | null;
  isEditable: boolean;
  trackId: string;
};

export type RiskBandCriteriaPopupProps = {
  rb6Criteria: number | null;
  isEditable: boolean;
  trackId: string;
};

export enum HomeResidenceTypes {
  RESIDENCE_TYPE_UNKNOWN = 'un',
  RESIDENCE_TYPE_RENTAL = 're',
  RESIDENCE_TYPE_PRIMARY_RESIDENCE = 'pr',
  RESIDENCE_TYPE_VACATION = 'va',
}

export type BulletItem = {
  label: React.ReactNode;
  value: React.ReactNode;
};

type HomeValuation = {
  __typename: string;
  identifier: string;
  value: number;
  type: string;
  shortType: string;
  expirationDate: string;
  daysUntilExpires: number;
};

export type BeginningHomeValuation = HomeValuation | null;
