import { Link } from 'react-router-dom';
import { isOnlyInvestmentManager, isInInvestmentManagerGroup } from 'userRoles';
import TrackLink from 'components/TrackLink/TrackLink';
import ClaimButton from './components/ClaimButton';
import dayjs from 'dayjs';
import { TRACK_STAGES } from 'data/constants/trackStages';
import { faker } from '@faker-js/faker';

export const TODOS_FILTER = 'open_homeowner_tasks';

export const isTrackClosedLost = track => {
  if (!track) return false;
  return track.currentStage?.stageClassName === TRACK_STAGES.ClosedLost || !!track.closedLostData;
};

export const formatLastLoginDate = date => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() && parsedDate.format('MM/DD/YYYY');
};

const getOpenTasksCount = (track, getting_homeowner_tasks) => {
  return track.openTasks?.filter(task => {
    if (task?.assignee) {
      return task.assignee?.userType === 'client' && getting_homeowner_tasks;
    }
    return !getting_homeowner_tasks;
  }).length;
};

export const getTrackColumns = (user, onTrackClaim) => {
  const userIsInInvestmentManagerGroup = isInInvestmentManagerGroup(user);
  const columns = [
    {
      name: 'Name',
      sortable: true,
      sortKey: 'fullNameShort',
      selector: row => row.fullNameShort,
    },
    {
      name: 'Address',
      selector: row => row.fullAddress,
      grow: 3,
    },
    {
      name: 'Friendly ID',
      sortable: true,
      sortKey: 'friendlyId',
      selector: row => row.friendlyId,
      cell: row => {
        return (
          <TrackLink
            friendlyId={row.friendlyId}
            trackId={row.identifier}
            disabled={userIsInInvestmentManagerGroup && !row.reviewer}
          />
        );
      },
    },
    {
      name: 'Current Stage',
      selector: row => row.currentStage?.label,
    },
    {
      name: 'Last Login',
      sortable: true,
      sortKey: 'lastLogin',
      selector: row => formatLastLoginDate(row.homeowner.lastLogin),
    },
    {
      isVisible: !isOnlyInvestmentManager(user),
      name: 'Pricing',
      selector: row => row.product.name,
    },
    {
      isVisible: !userIsInInvestmentManagerGroup,
      name: 'Open Tasks',
      maxWidth: '112px',
      sortable: true,
      sortKey: 'open_internal_tasks',
      selector: row => getOpenTasksCount(row, false),
      style: { justifyContent: 'right' },
    },
    {
      name: 'Open To-Dos',
      maxWidth: '120px',
      // TODO: disabled since BPM todos are not yet accounted for
      // sortable: false,
      // sortKey: TODOS_FILTER,
      selector: row => getOpenTasksCount(row, true),
      style: { justifyContent: 'right' },
      cell: row => {
        if (userIsInInvestmentManagerGroup && !row.reviewer) {
          return (
            <span data-testid={`${row.identifier}-todo-count`} className="DisabledLink">
              {getOpenTasksCount(row, true)}
            </span>
          );
        }

        return (
          <Link data-testid={`${row.identifier}-todo-count`} to={`${row.identifier}/todos`}>
            {getOpenTasksCount(row, true)}
          </Link>
        );
      },
    },
    {
      name: 'Actions',
      sortable: true,
      sortKey: 'reviewer',
      maxWidth: '108px',
      selector: row => null,
      cell: row =>
        userIsInInvestmentManagerGroup &&
        !row.reviewer && <ClaimButton onClick={() => onTrackClaim(row.identifier)} testId={row.friendlyId} />,
    },
  ];

  return columns.filter(({ isVisible = true }) => isVisible);
};

export const getTrackMobileColumns = (user, onTrackClaim) => [
  {
    name: 'Name',
    sortable: true,
    sortKey: 'fullNameShort',
    selector: row => row.fullNameShort,
  },
  {
    name: 'Address',
    selector: row => row.fullAddress,
    grow: 3,
  },
  {
    name: 'Actions',
    width: 100,
    right: true,
    grow: 0,
    selector: row => null,
    cell: row =>
      isInInvestmentManagerGroup(user) &&
      !row.reviewer && <ClaimButton onClick={() => onTrackClaim(row.identifier)} testId={row.friendlyId} />,
  },
];

export const getFakerUser = () => {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  const provider = 'hometap.com';
  const email = `test+${faker.internet.email(firstName, lastName, provider)}`;
  return { firstName, lastName, provider, email };
};
