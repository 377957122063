import { Button, Loader } from '@hometap/htco-components';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';
import React from 'react';
import { GET_BIRCHWOOD_DATA } from './getBirchwoodData';
import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import { formatBirchwoodData } from './formatBirchwoodData';

const CreditReport = () => {
  const { loading, data = {} } = useSpecificContentQuery(GET_BIRCHWOOD_DATA);
  const { track } = data;
  const applicants = track?.applicants || [];

  return (
    <div className="TaskDetailContent">
      <div className="CreditReportButtonRow">
        <h3>Birchwood</h3>
        <Button
          theme="link"
          target="_blank"
          rel="noreferrer noopener"
          href="https://birchwood.meridianlink.com/client/main_desktop.aspx"
        >
          Navigate to Birchwood
        </Button>

        <ul className="actionsTooltipList">
          {!loading && applicants ? (
            applicants.map((applicant, index) => (
              <li key={index}>
                {applicant.governmentId?.nameAsAppearsOnId ? (
                  <ActionsTooltip
                    value={JSON.stringify(formatBirchwoodData(track, applicant))}
                    className="CreditReportCopyContainer"
                    actions={[{ actionType: ActionTooltipOptions.Copy }]}
                  >
                    Copy data for {applicant.firstName} {applicant.lastName}
                  </ActionsTooltip>
                ) : (
                  `Error: There is no Government ID data for ${applicant.firstName} ${applicant.lastName}`
                )}
              </li>
            ))
          ) : (
            <Loader />
          )}
        </ul>
      </div>
    </div>
  );
};

export default CreditReport;
