import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Modal,
  MuiTextInput,
  useForm,
  MuiSelect,
  useAsync,
  Loader,
  Accordion,
} from '@hometap/htco-components';
import { mapOptions } from 'utils/data';
import { getFakerUser } from '../utils';
import { createTrack } from 'data/trackRequest';
import useDocumentKinds from 'apps/track-details/Documents/hooks/useDocumentKinds';
import './TrackCreation.scss';
import { showNotification, TOAST_TYPE } from '../../../utils/toasts';
import cx from 'classnames';
import { TrackCreationLinks } from './TrackCreationLinks';
import { hometapEmailValidator } from '../../../utils/validators';
import useConfigurations from 'hooks/useConfigurations';

const DEFAULT_TRACK_DATA = {
  first_name: '',
  last_name: '',
  email: '',
  stage_class_name: '',
  reviewer_id: '',
  co_applicants: [],
  document_kinds: [],
  lien_kinds: [],
  appraisal_type: '',
  property_type: '',
  residence_type: '',
  street: '',
  unit: '',
  city: '',
  state: '',
  zip_code: '',
};

const DEMO_TRACK_DATA = {
  property_type: 'sf',
  residence_type: 'pr',
  street: '3200 Mount Vernon Memorial Hwy',
  unit: '',
  city: 'Mount Vernon',
  state: 'VA',
  zip_code: '22121',
  stage_class_name: 'ApplicationInReview',
  appraisal_type: 'virtual_appraisal',
  document_kinds: ['gov_id'],
  lien_kinds: ['mortgage'],
};

const HOME_FIELDS = ['property_type', 'residence_type'];
const ADDRESS_FIELDS = ['street', 'unit', 'city', 'state', 'zip_code'];

const TrackCreation = data => {
  const useDocumentKindsResults = useDocumentKinds();
  const { lienKinds, allConfigurations, statesOptions } = useConfigurations();
  const { registerField, formData, updateFormData, isFormValid } = useForm(DEFAULT_TRACK_DATA);

  const [modelTrackOpen, setModelTrackOpen] = useState(false);
  const { residenceTypes, propertyTypes } = allConfigurations?.home || {};
  const { appraisalTypes } = allConfigurations?.appraisal || {};

  const [openAccordianKeys, setOpenAccordianKeys] = useState({});

  const toggleAccordian = useCallback(key => {
    setOpenAccordianKeys(prev => ({ ...prev, [key]: !prev[key] }));
  }, []);
  const {
    results: createTrackResults,
    execute: executeCreateTrack,
    loading,
    setError,
    setResults,
  } = useAsync(createTrack, {
    onSuccess: () => {
      updateFormData(DEFAULT_TRACK_DATA);
      setOpenAccordianKeys({});
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'New Track successfully created.',
      });
    },
    onError: error => {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to save changes',
        description: error.response?.data[0]
          ? error.response?.data[0]
          : 'Could not create New Track. Please try again.',
      });
      setError(null);
    },
  });

  const { stage_class_name } = formData;

  const isBeforeAppReview = ['ApplicationReady', 'ReadyToApply', 'ApplicationInProgress', ''].includes(
    stage_class_name,
  );

  useEffect(() => {
    if (isBeforeAppReview) {
      updateFormData({
        reviewer_id: '',
        document_kinds: [],
        co_applicants: [],
        lien_kinds: [],
        appraisal_type: '',
      });
    }
  }, [isBeforeAppReview, updateFormData]);

  const autoFillCreateTrackInfo = () => {
    const user = getFakerUser();

    updateFormData({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      reviewer_id: data?.investmentManagers?.edges?.[0]?.node?.identifier,
      ...DEMO_TRACK_DATA,
    });
  };

  const addCoApplicant = () => {
    const user = getFakerUser();
    updateFormData({
      co_applicants: [
        ...formData.co_applicants,
        { invited_email: user.email, first_name: user.firstName, last_name: user.lastName },
      ],
    });
  };

  const onConfirm = () => {
    const requestData = {
      home: {
        address: {},
      },
    };
    Object.entries(formData).forEach(([key, value]) => {
      if (HOME_FIELDS.includes(key)) {
        requestData.home[key] = value;
      } else if (ADDRESS_FIELDS.includes(key)) {
        requestData.home.address[key] = value;
      } else {
        requestData[key] = value;
      }
    });
    executeCreateTrack(requestData);
  };

  const onCancelModal = () => {
    updateFormData(DEFAULT_TRACK_DATA);
    setOpenAccordianKeys({});
    setModelTrackOpen(false);
    if (createTrackResults) {
      setResults(null);
    }
  };

  return (
    <>
      <IconButton
        className="TrackCreation CreateTrackButton"
        icon={'icon-plus'}
        onClick={() => {
          setModelTrackOpen(true);
        }}
        size="normal"
        theme="solid"
        width={null}
      />

      <Modal
        className={cx('CreateTrackModal', { loading })}
        open={modelTrackOpen}
        width={626}
        onClose={() => {
          setModelTrackOpen(false);
          onCancelModal();
        }}
      >
        {loading && <Loader typle="spinner" size="large" overlay />}

        <h2 className="MergeDocumentsModalTitle">Create New Track</h2>

        <div className="TwoItemFormRow">
          <div className="FormItem">
            <MuiSelect
              label="Select a Stage"
              name="selectStage"
              theme="outlined"
              width="100%"
              required
              options={mapOptions(data.stages, 'label', 'stageClassName')}
              showRequiredAsterisk={false}
              {...registerField('stage_class_name')}
            />
          </div>
          <div className="FormItem">
            <Button onClick={autoFillCreateTrackInfo} theme="primary" width="100%" className="FormRowButton">
              Demo
            </Button>
          </div>
        </div>

        <Accordion
          key="primary"
          isOpen={!!openAccordianKeys['primary']}
          onChange={() => toggleAccordian('primary')}
          theme="with-arrow"
          header="Primary Homeowner"
        >
          <div className="TwoItemFormRow">
            <div className="FormItem">
              <MuiTextInput
                label="First Name"
                theme="outlined"
                width="100%"
                autoComplete="nope"
                {...registerField('first_name')}
              />
            </div>
            <div className="FormItem">
              <MuiTextInput
                label="Last Name"
                theme="outlined"
                width="100%"
                autoComplete="nope"
                {...registerField('last_name')}
              />
            </div>
          </div>

          <div className="FormItem">
            <MuiTextInput
              label="Email"
              theme="outlined"
              width="100%"
              autoComplete="nope"
              validator={hometapEmailValidator}
              {...registerField('email')}
            />
          </div>
        </Accordion>

        <Accordion
          key="initial_app_review"
          isOpen={!!openAccordianKeys['staff']}
          onChange={() => toggleAccordian('staff')}
          theme="with-arrow"
          header="Initial Application Review"
          disabled={isBeforeAppReview}
        >
          <div className="FormItem">
            <Button
              className="FormRowButton"
              disabled={isBeforeAppReview}
              theme="primary"
              width="100%"
              onClick={() => addCoApplicant()}
            >
              Add Co-Applicant (<span className="CoApplicantCount">{formData?.co_applicants?.length}</span>)
            </Button>
          </div>
          <div className="TwoItemFormRow">
            <div className="FormItem">
              <MuiSelect
                label="Appraisal Type"
                name="selectAppraisalType"
                theme="outlined"
                width="100%"
                disabled={isBeforeAppReview}
                options={appraisalTypes}
                {...registerField('appraisal_type')}
              />
            </div>
            <div className="FormItem">
              <MuiSelect
                label="Select an Investment Manager"
                name="selectInvestmentManager"
                theme="outlined"
                width="100%"
                disabled={isBeforeAppReview}
                options={mapOptions(data.investmentManagers, 'fullNameShort', 'identifier')}
                {...registerField('reviewer_id')}
              />
            </div>
          </div>
          <div className="FormItem">
            <MuiSelect
              label="Document Kinds (optional)"
              name="selectDocumentKind"
              theme="outlined"
              width="100%"
              multiple={true}
              disabled={isBeforeAppReview}
              options={useDocumentKindsResults.documentKindsDropdownOptions.filter(option => option.value !== 'other')}
              {...registerField('document_kinds')}
            />
          </div>

          <div className="FormItem">
            <MuiSelect
              label="Lien Kinds (optional)"
              name="selectLienKind"
              theme="outlined"
              width="100%"
              multiple={true}
              disabled={isBeforeAppReview}
              options={lienKinds}
              {...registerField('lien_kinds')}
            />
          </div>
        </Accordion>
        <Accordion
          key="home"
          isOpen={!!openAccordianKeys['home']}
          onChange={() => toggleAccordian('home')}
          theme="with-arrow"
          header="Home"
        >
          <div className="FormItem">
            <MuiSelect
              label="Property Type"
              options={propertyTypes}
              theme="outlined"
              width="100%"
              {...registerField('property_type')}
            />
          </div>

          <div className="FormItem">
            <MuiSelect
              label="Occupancy Type"
              options={residenceTypes}
              theme="outlined"
              width="100%"
              {...registerField('residence_type')}
            />
          </div>
        </Accordion>

        <Accordion
          key="address"
          isOpen={!!openAccordianKeys['address']}
          onChange={() => toggleAccordian('address')}
          theme="with-arrow"
          header="Address"
        >
          <div className="TwoItemFormRow">
            <div className="FormItem">
              <MuiTextInput
                label="Street Address"
                theme="outlined"
                width="100%"
                autoComplete="nope"
                name="street"
                data-testid="street"
                maxLength={50}
                {...registerField('street')}
              />
            </div>
            <div className="FormItem">
              <MuiTextInput
                label="Unit (optional)"
                theme="outlined"
                width="100%"
                autoComplete="nope"
                name="unit"
                data-testid="unit"
                maxLength={50}
                {...registerField('unit')}
              />
            </div>
          </div>

          <div className="TwoItemFormRow">
            <div className="FormItem">
              <MuiTextInput
                theme="outlined"
                width="100%"
                autoComplete="nope"
                maxLength={50}
                name="city"
                label="City"
                data-testid="city"
                {...registerField('city')}
              />
            </div>
            <div className="FormItem">
              <MuiSelect
                theme="outlined"
                width="100%"
                maxLength={50}
                name="state"
                label="State"
                options={statesOptions}
                {...registerField('state')}
              />
            </div>
          </div>
          <div className="FormItem">
            <MuiTextInput
              theme="outlined"
              name="zip_code"
              label="Zip Code"
              type="number"
              inputMode="number"
              data-testid="zip_code"
              maxLength={5}
              mask="00000"
              validator={val => val.length !== 5 && 'Zip code must be 5 digits'}
              {...registerField('zip_code')}
              width="100%"
            />
          </div>
        </Accordion>

        <TrackCreationLinks
          email={createTrackResults?.data?.email}
          trackId={createTrackResults?.data?.track_id}
          friendlyId={createTrackResults?.data?.track_friendly_id}
        />

        <div className="CreateTrackModalButtons">
          <Button theme="text" onClick={onCancelModal}>
            Cancel
          </Button>
          <Button disabled={!isFormValid || loading} onClick={onConfirm}>
            Create Track
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TrackCreation;
