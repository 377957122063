import './UnderwritingDetailsController.scss';
import { Paper } from '@hometap/htco-components';
import { MultiPicker } from '../../components';
import { UnderwritingDetailsProps, BulletItem } from './types/types';
import { getValueOrDefault } from './helper';
import UnderwriterBulletList from './components/UnderwriterBulletList';
import DelinquentTaxesPopup from './components/DelinquentTaxesPopup';
import RiskBandCriteriaPopup from './components/RiskBandCriteriaPopup';
import { FinalApproveButton } from './components/FinalApproveButton/FinalApproveButton';
import APP_REVIEW_STATUSES from 'data/constants/applicationReviewStatuses';
import useCurrentUser from 'hooks/useCurrentUser';
import { UseCurrentUser } from 'hooks/types/types';
import { currencyWithCents, percentage } from 'utils/numbers';

const DEFAULT_STRING = '-';

const UnderwritingDetails = ({
  trackId,
  trackHistoryUrl,
  uwRuleVersion,
  applicationSpecialist,
  ownersEndingEquity,
  totalEndingEquity,
  underwriter,
  htLienPosition,
  principalPaydown,
  totalPaydown,
  maximumInvestmentAmount,
  beginnningHomeValuationLabel,
  beginnningHomeValuation,
  lienBalanceTotal,
  pastDueLienBalanceTotal,
  currentIncomeWeightedRiskBand,
  finanalCompositeFico,
  lossThreshold,
  shareUp,
  shareDown,
  trackReviewStatus,
  uwDscr,
  hasRentalResidentialType,
  rb6Criteria,
  highestSingleYearDelinquentTaxes,
  refetch,
}: UnderwritingDetailsProps) => {
  const { user, isInUnderwriterGroup } = useCurrentUser() as UseCurrentUser;

  const isUWCalcFieldsSummaryFlag = user?.rolloutFlags.includes('uw_calc_fields_in_summary_flag');
  const enabledApproveButton = trackReviewStatus === APP_REVIEW_STATUSES.uwApproved && isInUnderwriterGroup;
  const enableFieldEditing = trackReviewStatus === APP_REVIEW_STATUSES.uwReady && isInUnderwriterGroup;

  const overviewItems: BulletItem[] = [
    { label: 'Underwriting rule version', value: uwRuleVersion },
    { label: 'Application specialist', value: getValueOrDefault(applicationSpecialist) },
    { label: 'Underwriter', value: getValueOrDefault(underwriter) },
    { label: 'HT lien position', value: getValueOrDefault(htLienPosition?.toString()) },
    {
      label: 'Principal paydown',
      value: getValueOrDefault(principalPaydown, DEFAULT_STRING, currencyWithCents),
    },
    { label: 'Total paydown', value: getValueOrDefault(totalPaydown, DEFAULT_STRING, currencyWithCents) },
  ];

  if (isUWCalcFieldsSummaryFlag) {
    overviewItems.push(
      {
        label: 'Risk band 6 criteria',
        value: <RiskBandCriteriaPopup isEditable={enableFieldEditing} rb6Criteria={rb6Criteria} trackId={trackId} />,
      },
      {
        label: 'Highest single year delinquent taxes',
        value: (
          <DelinquentTaxesPopup
            highestSingleYearDelinquentTaxes={highestSingleYearDelinquentTaxes}
            isEditable={enableFieldEditing}
            trackId={trackId}
          />
        ),
      },
    );
  }

  if (isUWCalcFieldsSummaryFlag && hasRentalResidentialType) {
    overviewItems.push({
      label: 'DSCR',
      value: getValueOrDefault(uwDscr, '0', v => `$${v.toLocaleString()}`),
    });
  }

  const dealTermsItems1: BulletItem[] = [
    {
      label: 'Maximum investment amount',
      value: getValueOrDefault(maximumInvestmentAmount, DEFAULT_STRING, currencyWithCents),
    },
    {
      label: (
        <>
          <div>Beginning home valuation</div>
          <div>{beginnningHomeValuationLabel}</div>
        </>
      ),
      value: getValueOrDefault(beginnningHomeValuation, DEFAULT_STRING, currencyWithCents),
    },
    {
      label: 'Lien balance total',
      value: getValueOrDefault(lienBalanceTotal, DEFAULT_STRING, currencyWithCents),
    },
    {
      label: 'Past due lien balance total',
      value: getValueOrDefault(pastDueLienBalanceTotal, DEFAULT_STRING, currencyWithCents),
    },
    {
      label: "Owner's ending equity",
      value: getValueOrDefault(ownersEndingEquity, DEFAULT_STRING, v => `${v.toLocaleString()}%`),
    },
    {
      label: 'Total ending equity',
      value: getValueOrDefault(totalEndingEquity, DEFAULT_STRING, v => `${v.toLocaleString()}%`),
    },
  ];

  const percentageFormatter = isUWCalcFieldsSummaryFlag ? (v: any) => percentage(v * 100) : percentage;

  const dealTermsItems2: BulletItem[] = [
    { label: 'Loss threshold', value: getValueOrDefault(lossThreshold, DEFAULT_STRING, percentageFormatter) },
    { label: 'Current income weighted risk band', value: getValueOrDefault(currentIncomeWeightedRiskBand?.toString()) },
    { label: 'Final composite fico', value: getValueOrDefault(finanalCompositeFico?.toString()) },
    { label: 'Share up', value: getValueOrDefault(shareUp, DEFAULT_STRING, percentageFormatter) },
    { label: 'Share down', value: getValueOrDefault(shareDown, DEFAULT_STRING, percentageFormatter) },
  ];

  const onApproveSuccess = () => refetch?.();

  return (
    <div className="ApplicationReviewSection ReadonlyUWWrapper">
      <Paper theme="plain" className="MultiPickerContainer ReadonlyUWMultipicker">
        <MultiPicker
          sectionTitle="Underwriting details"
          historyUrl={trackHistoryUrl}
          visibleSummary={false}
          pickList={[]}
          selectedId={0}
          sectionAlert={{}}
          onEdit={null}
        />
      </Paper>

      <Paper theme="plain" className="FormDetailsContainer UnderwritingDetailsContainer">
        <div className="UnderwritingDetails">
          <div className="Section">
            <h3>OVERVIEW</h3>
            <UnderwriterBulletList items={overviewItems} />
          </div>

          <div className="Section DealTermsSection">
            <h3>DEAL TERMS</h3>
            <div className="DealTermsContainer">
              <UnderwriterBulletList items={dealTermsItems1} />
              <UnderwriterBulletList items={dealTermsItems2} />
            </div>
          </div>

          <FinalApproveButton enabled={enabledApproveButton} onSuccess={onApproveSuccess} />
        </div>
      </Paper>
    </div>
  );
};

export default UnderwritingDetails;
