import { useState } from 'react';
import { Modal, Button, CurrencyInput, useForm } from '@hometap/htco-components';
import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';
import { DelinquentTaxesPopupProps } from '../types/types';
import { useMutation } from '@apollo/client';
import { UPDATE_HIGHEST_SINGLE_YEAR_DELINQUENT_TAXES } from '../queries';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { String } from 'aws-sdk/clients/acm';

const DelinquentTaxesPopup = ({ highestSingleYearDelinquentTaxes, isEditable, trackId }: DelinquentTaxesPopupProps) => {
  const { registerField, handleSubmit } = useForm();
  const [showEditModal, setShowEditModal] = useState(false);
  const [initialValue, setInitialValue] = useState(highestSingleYearDelinquentTaxes);

  const closeModal = () => {
    setShowEditModal(false);
  };
  const openModal = () => setShowEditModal(true);

  const [updateDelinquentTaxes, { loading }] = useMutation(UPDATE_HIGHEST_SINGLE_YEAR_DELINQUENT_TAXES, {
    onCompleted: result => {
      if (result.updateHighestSingleYearDelinquentTaxes.error) {
        setInitialValue(highestSingleYearDelinquentTaxes);
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save',
          description: `Could not update the value, ${result.updateHighestSingleYearDelinquentTaxes.error}`,
        });
      } else {
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Underwriting Field Updated',
          description: 'Highest Single Year Delinquent Taxes Field Updated',
        });
      }
    },
    onError: e => {
      setInitialValue(highestSingleYearDelinquentTaxes);
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to save',
        description: 'Could not update the value please try again',
      });
    },
  });

  const onSubmit = (data: { highestSingleYearDelinquentTaxes: String }) => {
    setInitialValue(data.highestSingleYearDelinquentTaxes);
    updateDelinquentTaxes({
      variables: {
        trackId: trackId,
        highestSingleYearDelinquentTaxes: data.highestSingleYearDelinquentTaxes,
      },
    });
    setShowEditModal(false);
  };

  let formatedFieldValue;

  if (initialValue === null) {
    formatedFieldValue = isEditable ? 'Add value' : '-';
  } else {
    formatedFieldValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(parseFloat(initialValue));
  }

  return (
    <>
      <LoadingContainer isLoading={loading}>
        {isEditable ? (
          <ActionsTooltip
            className=""
            actions={[
              {
                actionType: ActionTooltipOptions.Edit,
                onEdit: () => openModal(),
              },
            ]}
          >
            {formatedFieldValue}
          </ActionsTooltip>
        ) : (
          formatedFieldValue
        )}

        {showEditModal && (
          <Modal className="UnderwritingModal" open={showEditModal} onClose={closeModal} width={536}>
            <form className="UnderwritingModalDetails" onSubmit={handleSubmit(onSubmit)}>
              <h1 className="UnderwritingModalTitle">Edit</h1>

              <div className="UnderwritingModalSubTitle">Enter new value for highest single year delinquent taxes</div>

              <CurrencyInput
                label="Highest Single Year Delinquent Taxes"
                required={true}
                showRequiredAsterisk={false}
                {...registerField('highestSingleYearDelinquentTaxes')}
              />

              <Button type="submit" className="UnderwritingModalButton" size="large">
                Save
              </Button>
            </form>
          </Modal>
        )}
      </LoadingContainer>
    </>
  );
};

export default DelinquentTaxesPopup;
