import { ReactNode } from 'react';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';

export type ActionType = {
  actionType: ActionTooltipOptions;
  onEdit?: (() => void) | string;
  value?: string;
  emailAddress?: string;
  phoneNumber?: string;
  toLink?: string;
  name?: string;
  trackId?: string;
  isCoapplicant?: boolean;
};

export enum MagicLinkState {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

export type MagicLinkStateValue = Promise<void> | null;

export type ActionsTooltipProps = {
  className?: string;
  value?: string | number;
  actions?: ActionType[];
  transitionTime?: number;
  children?: ReactNode;
};

export type GetLinkContentsProps = {
  name: string;
};

export type EmailElementProps = {
  emailAddress: string;
  onClose: () => void;
};

export type PhoneElementProps = {
  phoneNumber: string;
  onClose: () => void;
};

export type LinkElementProps = {
  toLink: string;
  name: string;
};

export type EditElementProps = {
  onEdit: ((value?: string | number) => void) | string;
  onClose: () => void;
  value?: string;
};

export type SendMagicLinkElementProps = {
  onClick: () => void;
  state: MagicLinkState;
};

export type getEnabledActionProps = {
  actions: ActionType[];
  actionType: ActionTooltipOptions;
};
