import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

const GET_CONFIGURATIONS = gql`
  query GetConfigurations {
    configurations {
      home {
        residenceTypes {
          value
          label
        }
        propertyTypes {
          value
          label
        }
      }
      lien {
        kind {
          label
          value
        }
        mortgageInvestorTypes {
          label
          value
        }
        rateTypes {
          label
          value
        }
        paymentMethods {
          label
          value
        }
        lienKindsPrincipalAmountBalance
      }
      insurancePolicy {
        insuranceProviders
      }
      task {
        taskKinds {
          label
          value
        }
        smartTaskKinds {
          label
          value
        }
        taskDocumentKinds {
          kind
          documentType
        }
      }
      document {
        documentKinds {
          code
          oldCode
          displayName
          isInternalOnly
          canUpload
          canEdit
          canDelete
        }
        documentKindsWithPerson
      }
      lien {
        mortgageInvestorTypes {
          label
          value
        }
        rateTypes {
          label
          value
        }
        documentKinds {
          kind
          documentKind
        }
        kindsRequiringPayoff {
          kind
          conditions {
            propertyName
            value
          }
        }
      }
      appraisal {
        propertyConditions {
          label
          value
        }
        appraisalTypes {
          label
          value
        }
      }
      states {
        name
        acronym
        status
      }
      uwRuleVersion
    }
  }
`;

export const Context = React.createContext({});

const useConfigurations = () => useContext(Context);
export default useConfigurations;

export function ConfigurationsProvider({ children }) {
  const [getConfigurations, { data, loading }] = useLazyQuery(GET_CONFIGURATIONS);
  const [allConfigurations, setAllConfigurations] = useState({});
  const [taskKinds, setTaskKinds] = useState({});
  const [newTaskKinds, setNewTaskKinds] = useState([]);
  const [documentKinds, setDocumentKinds] = useState([]);
  const [taskDocumentKinds, setTaskDocumentKinds] = useState([]);
  const [lienKindToDocKind, setLienKindToDocKind] = useState({});
  const [documentKindsWithPerson, setDocumentKindsWithPerson] = useState([]);
  const [propertyConditions, setPropertyConditions] = useState({});
  const [residenceTypes, setResidenceTypes] = useState({});
  const [statesOptions, setStatesOptions] = useState([]);
  const lienKinds = allConfigurations?.lien?.kind ?? [];
  const lienMortgageInvestorTypes = allConfigurations?.lien?.mortgageInvestorTypes ?? [];
  const lienRateTypes = allConfigurations?.lien?.rateTypes ?? [];
  const lienPaymentMethods = allConfigurations?.lien?.paymentMethods ?? [];
  const lienKindsRequiringPayoff = allConfigurations?.lien?.kindsRequiringPayoff ?? [];
  const lienKindsPrincipalAmountBalance = allConfigurations?.lien?.lienKindsPrincipalAmountBalance ?? [];
  const uwRuleVersion = allConfigurations?.uwRuleVersion ?? '';

  useEffect(() => {
    getConfigurations().then(() => null);
  }, [getConfigurations]);

  useEffect(() => {
    if (!loading && data?.configurations) {
      const getLabelValueSet = data => {
        const labelValueObj = {};
        data?.forEach(labelValue => {
          labelValueObj[labelValue.label] = labelValue.value;
        });
        return labelValueObj;
      };
      setTaskKinds(getLabelValueSet(data?.configurations?.task?.taskKinds));
      setNewTaskKinds(data?.configurations?.task?.smartTaskKinds?.map(labelValue => labelValue.value) ?? []);
      setDocumentKinds(data?.configurations.document?.documentKinds ?? []);
      setAllConfigurations(data?.configurations ?? {});
      setTaskDocumentKinds(data?.configurations?.task?.taskDocumentKinds ?? []);
      setLienKindToDocKind(
        data?.configurations?.lien?.documentKinds?.reduce((acc, { kind, documentKind }) => {
          acc[kind] = documentKind;
          return acc;
        }, {}) ?? {},
      );
      setDocumentKindsWithPerson(data?.configurations?.document?.documentKindsWithPerson ?? []);
      setPropertyConditions(data?.configurations?.appraisal?.propertyConditions ?? []);
      setResidenceTypes(getLabelValueSet(data?.configurations?.home?.residenceTypes));
      setStatesOptions(
        data?.configurations?.states?.map(state => ({ label: state.acronym, value: state.acronym })) ?? [],
      );
    }
  }, [loading, data]);

  const contextData = {
    allConfigurations,
    taskKinds,
    newTaskKinds,
    documentKinds,
    taskDocumentKinds,
    lienKindToDocKind,
    documentKindsWithPerson,
    propertyConditions,
    residenceTypes,
    lienKinds,
    lienMortgageInvestorTypes,
    lienRateTypes,
    lienPaymentMethods,
    lienKindsRequiringPayoff,
    lienKindsPrincipalAmountBalance,
    uwRuleVersion,
    statesOptions,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
}
